import { ACTIONS } from './types';

const actions = {
	afterAuth0: (data: any, checkAuth: boolean = false) => {
		if (!data) {
			return {
				type: ACTIONS.LOGIN_ERROR,
				error: 'invalid token',
			};
		}
		
		const _ = {
			...data,
			user: {
				login_count: data.user['https://golee-eu.appspot.com/app_metadata/login_count'],
				name: data.user.name,
				nickname: data.user.nickname,
				picture: data.user.picture,
				sub: data.user.sub,
				updated_at: data.user.updated_at,
			},
		};

		return {
			type: ACTIONS.AFTER_AUTH0_LOGIN,
			payload: _,
			checkAuth,
		};
	},

	actSetPermissionFeatures: (payload: any) => {
		const manager_user_perm = payload.find(
			(user_perm: any) =>
				user_perm.role === 'club_manager' || user_perm.role === 'club_manager_demo'
		);

		return {
			type: ACTIONS.SETPERMISSIONFEATURES,
			payload: manager_user_perm,
		};
	},

	havePermission: (permission: any) => (dispatch: any, getState: any) => {
		if (permission) {
			return !!(
				permission &&
				permission.some((userperm: any) =>
					getState().Auth.user_permissions.permissions.includes(userperm)
				)
			);
		} else {
			return true;
		}
	},

	haveFeature: (feature: string) => (dispatch: any, getState: any) => {
		if (feature) {
			return !!(feature && getState().Auth.user_permissions.features.includes(feature));
		} else {
			return true;
		}
	},
};

export default actions;
