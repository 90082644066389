export type TStateAuth = {
	access_token: string | null;
	georgian_token: string | null;
	login_error: any;
	login_completed: boolean;
	user_permissions: any;
	client_id: string | null;
	profile: any;
	club_details: any;
};

export enum ACTIONS {
	AFTER_AUTH0_LOGIN = 'AFTER_AUTH0_LOGIN',
	LOGIN_SUCCESS = 'LOGIN_SUCCESS',
	LOGIN_ERROR = 'LOGIN_ERROR',
	SETPERMISSIONFEATURES = 'SETPERMISSIONFEATURES',
	SET_LOGINCOUNT = 'SET_LOGINCOUNT',
}
