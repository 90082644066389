import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from '../../../../../components/Toast/toast';
import {
	$approveMatchReport,
	$forwardMatchReport,
	$loadSingleMatchReport,
	$rejectMatchReport,
} from '../../../../../redux/MatchReports/middleware';
import { history } from '../../../../../redux/store';
import { TGlobalState } from '../../../../../redux/types';
import View from './view';

const Container = ({ ...props }) => {
	const { current, loading } = useSelector((store: TGlobalState) => store.MatchReports);
	const dispatch = useDispatch();

	const { id } = props.match.params;

	useEffect(() => {
		if ((!current || current._id !== id) && !loading) {
			dispatch(
				$loadSingleMatchReport(id, err => {
					toast.error('Richiesta non trovata');
					dispatch(push('./'));
				})
			);
		}
	}, [current]);

	return (
		<>
			{current && (
				<View
					item={current}
					onApprove={item => {
						dispatch(
							$approveMatchReport(
								item,
								() => {
									toast.error('Impossibile approvare richiesta');
								},
								() => {
									toast.success('Richiesta approvata');
								}
							)
						);
						history.goBack();
					}}
					onReject={(item, reason) => {
						dispatch(
							$rejectMatchReport(
								item,
								reason,
								() => {
									toast.error('Impossibile rifiutare richiesta');
								},
								() => {
									toast.success('Richiesta rifiutata');
								}
							)
						);
						history.goBack();
					}}
					onForward={(item, delegation) => {
						dispatch(
							$forwardMatchReport(
								item,
								delegation,
								() => {
									toast.error('Impossibile inoltrare richiesta');
								},
								() => {
									toast.success('Richiesta inoltrata');
								}
							)
						);
						history.goBack();
					}}
				/>
			)}
		</>
	);
};

export default Container;
