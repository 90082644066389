import axios from './interceptor';
import api from './api';
import { Document } from '../types';

const service = {
	list: async (refEntity: string, typeEntity: string): Promise<Document[]> => {
		try {
			const resp = await axios.get(api.list(), {
				params: {
					ref_entity: refEntity,
					type_entity: typeEntity,
				},
			});
			return resp.data;
		} catch (err) {
			throw err;
		}
	},

	create: async (data: Document): Promise<Document> => {
		try {
			const payload: any = {
				...data,
				ref_entity: data.refEntity,
				type_entity: data.typeEntity,
			};

			delete payload.refEntity;
			delete payload.typeEntity;
			delete payload._id;

			const resp = await axios.post(api.create(), payload);
			return resp.data;
		} catch (err) {
			throw err;
		}
	},

	edit: async (_id: string, data: Document): Promise<Document> => {
		try {
			const payload: any = {
				...data,
				ref_entity: data.refEntity,
				type_entity: data.typeEntity,
			};

			delete payload.refEntity;
			delete payload.typeEntity;

			const resp = await axios.put(api.edit(_id), payload);
			return resp.data;
		} catch (err) {
			throw err;
		}
	},

	delete: async (_id: string): Promise<void> => {
		try {
			return await axios.delete(api.delete(_id));
		} catch (err) {
			throw err;
		}
	},

	createMany: async (data: Document[]): Promise<Document[]> => {
		let output: Document[] = [];

		for (let doc of data) {
			output.push(await service.create(doc));
		}

		return output;
	},
};

export default service;
