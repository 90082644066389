import { EDelegations, ERegions } from '@golee/gle-types';
import { ACTIONS, TActions, TStateDelegations } from './types';

const initialState: TStateDelegations = {
	data: [],
	current: undefined,
	loading: false,
	currentValue: undefined,
	province: undefined,
	region: undefined,
};

export default function appReducer(state = initialState, action: TActions): TStateDelegations {
	switch (action.type) {
		case ACTIONS.SET_LOADING: {
			return {
				...state,
				loading: action.payload,
			};
		}

		case ACTIONS.SET: {
			return {
				...state,
				data: [...state.data, ...action.payload],
				loading: false,
			};
		}

		case ACTIONS.SET_CURRENT: {
			return {
				...state,
				current: action.payload,
				currentValue:
					action.payload.type === 'PRO'
						? (action.payload.province! as EDelegations)
						: (action.payload.region! as EDelegations),
				region:
					action.payload.type === 'REG' ? (action.payload.region as ERegions) : undefined,
				province:
					action.payload.type === 'PRO'
						? (action.payload.province as EDelegations)
						: undefined,
			};
		}

		default:
			return state;
	}
}
