import { ACTIONS, TActionTypes, TStateApp } from './types';

const initState: TStateApp = {
	connectedToEywa: false,
};

const reducer = (state = initState, action: TActionTypes): TStateApp => {
	switch (action.type) {
		case ACTIONS.UPDATE_STATUS: {
			return {
				...state,
				...action.payload,
			};
		}

		case ACTIONS.RESET_STATUS: {
			return initState;
		}

		default:
			return state;
	}
};

export default reducer;
