import { ERichiestaAmichevoleStatus, TObjRichiestaAmichevole } from '@golee/gle-types';
import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from '../../../components/Toast/toast';
import { DataBridge } from '../../../providers/DataBridge';
import routes from '../../../router/routes';
import { FriendlyRequestsService } from '../../../services';
import View from './view';

const Container = ({ ...props }) => {
	const dispatch = useDispatch();
	const [current, setCurrent] = useState<TObjRichiestaAmichevole>();

	const { id } = props.match.params;

	const dispatchUpdate = (payload: TObjRichiestaAmichevole) => {
		DataBridge.Instance.dispatch({
			type: 'update',
			payload,
		});
	};

	const onLoad = async () => {
		try {
			const item: TObjRichiestaAmichevole = await FriendlyRequestsService.getOne(id);

			setCurrent(item);
		} catch (err) {
			toast.error('Richiesta non trovata');
			dispatch(push('./'));
		}
	};

	const onApprove = async (item: TObjRichiestaAmichevole) => {
		try {
			await FriendlyRequestsService.approve(item._id);
			toast.success('Richiesta approvata');

			dispatchUpdate({
				...item,
				status: ERichiestaAmichevoleStatus.APPROVED,
			});
			dispatch(push(routes.FRIENDLY_MATCH_REQUESTS));
		} catch (e) {
			toast.error('Impossibile approvare richiesta');
		}
	};

	const onReject = async (item: TObjRichiestaAmichevole, reason: string) => {
		try {
			await FriendlyRequestsService.reject(item._id, reason);
			toast.success('Richiesta rifiutata');

			dispatchUpdate({
				...item,
				status: ERichiestaAmichevoleStatus.REJECTED,
				rejection_motivation: reason,
			});
			dispatch(push(routes.FRIENDLY_MATCH_REQUESTS));
		} catch (e) {
			toast.error('Impossibile rifiutare richiesta');
		}
	};

	const onForward = async (item: TObjRichiestaAmichevole, delegation: string) => {
		try {
			await FriendlyRequestsService.forward(item._id, delegation);
			toast.success('Richiesta inoltrata');

			dispatchUpdate({
				...item,
				status: ERichiestaAmichevoleStatus.FORWARDED,
			});
			dispatch(push(routes.FRIENDLY_MATCH_REQUESTS));
		} catch (e) {
			toast.error('Impossibile inoltrare richiesta');
		}
	};

	useEffect(() => {
		onLoad();
	}, []);

	return (
		<>
			{current && (
				<View
					item={current}
					onApprove={onApprove}
					onReject={onReject}
					onForward={onForward}
				/>
			)}
		</>
	);
};

export default Container;
