import { ACTIONS, TActions, TStateNotifications } from './types';

const initialState: TStateNotifications = {
	data: [],
	unread: 0,
	loading: false,
	canLoadMore: true,
	limit: 10,
	connectionOpened: false,
};

export default function appReducer(state = initialState, action: TActions): TStateNotifications {
	switch (action.type) {
		case ACTIONS.CONNECTION_OPENED: {
			return {
				...state,
				connectionOpened: true,
			};
		}

		case ACTIONS.CONNECTION_CLOSED: {
			return {
				...state,
				connectionOpened: false,
			};
		}

		case ACTIONS.LOADING: {
			return {
				...state,
				loading: true,
				canLoadMore: false,
			};
		}

		case ACTIONS.SET: {
			return {
				...state,
				unread: action.payload.unread,
				data: [...state.data, ...action.payload.data],
				loading: false,
				canLoadMore: !(action.payload.data.length < state.limit),
			};
		}

		case ACTIONS.NEW: {
			return {
				...state,
				unread: state.unread + 1,
				data: [action.payload, ...state.data],
			};
		}

		case ACTIONS.READ: {
			let data = [...state.data];

			let index = data.findIndex(item => item._id === action.payload.notification._id);

			let recipientIndex = data[index].recipients.findIndex(
				(item: any) => item.recipient === action.payload.user
			);

			data[index].recipients[recipientIndex].read = true;

			return {
				...state,
				unread: state.unread - 1 > 0 ? state.unread - 1 : 0,
				data: [...data],
			};
		}

		case ACTIONS.READ_ALL: {
			let data = [...state.data];

			// Mark as read on store
			for (let i = 0; i < data.length; i++) {
				let recipientIndex = data[i].recipients.findIndex(
					(item: any) => item.recipient === action.payload.user
				);

				// Mark as read on store
				data[i].recipients[recipientIndex].read = true;
			}

			return {
				...state,
				unread: 0,
				data,
			};
		}

		default:
			return state;
	}
}
