import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Subscription } from 'rxjs';
import Eywa from '../../../eywa/eywa';
import { DataContext, HttpDataProvider } from '../../../providers';
import { DataBridge } from '../../../providers/DataBridge';
import routes from '../../../router/routes';
import { FriendlyRequestsService } from '../../../services';
import View from './view';

const Container = () => {
	const dispatch = useDispatch();

	let subscription: Subscription;

	const attachToEywa = () => {
		subscription = Eywa.Instance.messages().subscribe(message => {
			switch (message.event) {
				case 'RichiestaAmichevoleRejectedEvent':
				case 'RichiestaAmichevoleApprovedEvent':
					{
						DataBridge.Instance.dispatch({
							type: 'update',
							payload: message.payload.richiesta_amichevole_obj,
						});
					}
					break;

				case 'RichiestaAmichevoleForwardedEvent':
					{
						DataBridge.Instance.dispatch({
							type: 'update',
							payload: message.payload.source_richiesta_amichevole_obj,
						});
					}
					break;
			}
		});
	};

	const detachFromEywa = () => {
		if (subscription) {
			subscription.unsubscribe();
		}
	};

	useEffect(() => {
		attachToEywa();

		return () => {
			detachFromEywa();
		};
	}, []);

	return (
		<>
			<HttpDataProvider
				api={{
					function: FriendlyRequestsService.list,
				}}>
				<DataContext.Consumer>
					{context => (
						<>
							<View
								data={context.data}
								loading={context.loading}
								onFilter={context.onFilter}
								onSearch={context.onSearch}
								onSort={context.onSort}
								pagination={context.pagination}
								onRowClick={item => {
									dispatch(push(`${routes.FRIENDLY_MATCH_REQUESTS}/${item._id}`));
								}}
							/>
						</>
					)}
				</DataContext.Consumer>
			</HttpDataProvider>
		</>
	);
};

export default Container;
