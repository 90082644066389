import styled from 'styled-components';
import { colors } from '../../utils/colors';

export default styled.div`
	.header {
		display: flex;
		gap: 20px;
		border-bottom: 1px solid ${colors['light_grey_1']};
		margin: 0 20px;

		.tab {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 10px 20px;
			&:hover {
				cursor: pointer;
				color: ${colors['primary']};
			}
		}
		.inactive {
			border-bottom: 0px;
		}

		.active {
			border-bottom: 2px solid ${colors['primary_light']};
			color: ${colors['primary_dark']};
			font-weight: bold;
		}
	}

	.tab-pane-wrapper {
		margin-top: 20px;
	}
`;
