import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { TGlobalState } from '../../redux/types';
import { ClubManagerService } from '../../services/People';
import Typography from '../Typography';
import { InvitationModal } from './InvitationModal';
import Button from '../Button';

type TProps = {
	club_id: number;
};

const InviteManager = ({ club_id }: TProps): any => {
	const [hasGolee, setHasGolee] = useState<boolean>();
	const [isInvitationModalVisible, setIsInvitationModalVisible] = useState<boolean>(false);
	const messages = useSelector((store: TGlobalState) => store.Locale.messages);

	useEffect(() => {
		ClubManagerService.has_golee(club_id).then((resp: any) =>
			setHasGolee(resp.has_club_manager)
		);
	}, []);

	const showInvitationModal = () => {
		!hasGolee && setIsInvitationModalVisible(true);
	};

	const getText = () =>
		hasGolee ? (
			<Typography.Text level={3}>{messages['club_has_golee']}</Typography.Text>
		) : (
			<>
				<Typography.Text level={3}>{`${messages['club_has_not_golee']}.`}</Typography.Text>
				<Button
					onClick={showInvitationModal}
					style={{ fontSize: 11, padding: '4px 10px', borderRadius: 5 }}
					color={'primary'}
					type={'outline'}>
					{messages['click_to_invite']}
				</Button>
			</>
		);

	return (
		<div>
			{getText()}
			<InvitationModal
				show={isInvitationModalVisible}
				onClose={() => {
					setIsInvitationModalVisible(false);
				}}
			/>
			<ReactTooltip />
		</div>
	);
};

export { InviteManager };
