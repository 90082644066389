import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../utils/colors';
import Typography from '../../../Typography';
import { TSortDirection, TSortFunction } from '../types';

const Styled = styled.div`
	th {
		cursor: pointer;
	}
	.sort {
		gap: 2px;
		display: flex;
		flex-direction: column;
		margin-left: 5px;
		svg {
			fill: #eee;
			cursor: pointer;
			&.active {
				fill: ${colors['primary']};
			}
		}
	}
`;

type TProps = {
	title?: string;
	id?: string;
	onSort?: TSortFunction;
	current?: { id: string; way: TSortDirection };
};

const Sort = ({ ...props }: TProps) => {
	const [way, setWay] = useState<TSortDirection>();

	const onSort = () => {
		if (!way) {
			setWay('asc');
			return;
		}

		if (way === 'asc') {
			setWay('desc');
			return;
		}

		if (way === 'desc') {
			setWay(undefined);
			return;
		}
	};

	const getStringified = (_way: string, field: string) => {
		let string = '';

		if (_way === 'desc') {
			string = '-';
		}

		string += field;

		return string;
	};

	useEffect(() => {
		if (!props.id) {
			return;
		}

		if (!props.onSort) {
			return;
		}

		if (way) {
			props.onSort({ id: props.id, way, stringified: getStringified(way, props.id) });
		} else {
			props.onSort();
		}
	}, [way]);

	return (
		<Styled>
			<th onClick={() => onSort()}>
				<Typography.Text level={2}>{props.title}</Typography.Text>
				<div className="sort">
					<svg
						height="6"
						width="8"
						className={`${
							props.current?.id === props.id && way === 'asc' ? 'active' : ''
						} sort-up`}>
						<polygon points="0,6 4,0 8,6" />
					</svg>
					<svg
						height="6"
						width="8"
						className={`${
							props.current?.id === props.id && way === 'desc' ? 'active' : ''
						} sort-down`}>
						<polygon points="0,0 4,6 8,0" />
					</svg>
				</div>
			</th>
		</Styled>
	);
};

export default Sort;
