import {
	TObjRichiestaVariazione,
	TObjRefertoGara,
	TObjRichiestaAmichevole,
} from '@golee/gle-types';
import { useSelector } from 'react-redux';
import { TGlobalState } from '../../redux/types';

type TProps = {
	item: TObjRichiestaVariazione | TObjRefertoGara | TObjRichiestaAmichevole;
};

const Place = ({ item, ...props }: TProps) => {
	const { countries, regions } = useSelector((state: TGlobalState) => state.Locations);
	const { language } = useSelector((state: TGlobalState) => state.Locale);

	const getLabel = (): string => {
		let array: string[] = [];

		if (item.match_place) {
			array.push(item.match_place);
		}

		if (item.region) {
			if (regions[item.region]) {
				array.push(regions[item.region][language]);
			}
		}

		if (item.nation) {
			if (regions[item.nation]) {
				array.push(countries[item.nation][language]);
			}
		}

		if (!array.length) {
			return '-';
		}

		return array.join(', ');
	};

	return <>{getLabel()}</>;
};

export default Place;
