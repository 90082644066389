import { TObjChampionship } from '@golee/gle-types';

export type TStateChampionships = {
	data: TObjChampionship[];
	loading: boolean;
	offset: number;
	limit: number;
	canLoadMore: boolean;
	current?: TObjChampionship;
};

export enum ACTIONS {
	SET_LOADING = 'SET_LOADING_CHAMPIONSHIPS',
	LOAD = 'LOAD_SET_CHAMPIONSHIPS',
	SET = 'SET_CHAMPIONSHIPS',
	SET_CURRENT = 'SET_CURRENT_CHAMPIONSHIP',
}

export type TActLoading = {
	type: ACTIONS.SET_LOADING;
	payload: boolean;
};

export type TActLoad = {
	type: ACTIONS.LOAD;
	payload: TObjChampionship[];
};

export type TActSet = {
	type: ACTIONS.SET;
	payload: TObjChampionship[];
};

export type TActSetCurrent = {
	type: ACTIONS.SET_CURRENT;
	payload: TObjChampionship;
};

export type TActions = TActLoading | TActSet | TActSetCurrent | TActLoad;
