import { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../../components/Button';
import { DelegationsSelector } from '../../../../../../components/DelegationsSelector';
import Typography from '../../../../../../components/Typography';
import { TGlobalState } from '../../../../../../redux/types';

type TProps = {
	onCancel: () => void;
	onConfirm: (delegation: string) => void;
};

const ForwardRequest = ({ ...props }: TProps) => {
	const messages = useSelector((store: TGlobalState) => store.Locale.messages);

	const [delegation, setDelegation] = useState<string>('');

	return (
		<>
			<Typography.Title level={4}>Seleziona delegazione</Typography.Title>
			<DelegationsSelector onChange={setDelegation} />

			<div
				style={{
					marginTop: 20,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					gap: 15
				}}>
				<Button color="default" type="link" onClick={() => props.onCancel()}>
					{messages['cancel']}
				</Button>
				<Button
					color="primary"
					disabled={!delegation}
					onClick={() => props.onConfirm(delegation)}>
					{messages['forward']}
				</Button>
			</div>
		</>
	);
};

export default ForwardRequest;
