import { ACTIONS, TActions, TStateMatchReports } from './types';

const initialState: TStateMatchReports = {
	data: [],
	loading: false,
	canLoadMore: true,
	offset: 0,
	limit: 20,
	current: undefined,
	filters: {},
	sort: {},
};

export default function appReducer(state = initialState, action: TActions): TStateMatchReports {
	switch (action.type) {
		case ACTIONS.SET_LOADING: {
			return {
				...state,
				loading: action.payload,
			};
		}

		case ACTIONS.SET_FILTERS: {
			return {
				...state,
				filters: action.payload,
				offset: 0,
			};
		}

		case ACTIONS.ADD_FILTER: {
			return {
				...state,
				filters: { ...state.filters, ...action.payload },
			};
		}

		case ACTIONS.SET_SORT: {
			return {
				...state,
				sort: action.payload,
				offset: 0,
			};
		}

		case ACTIONS.SET_OFFSET: {
			return {
				...state,
				offset: action.payload,
			};
		}

		case ACTIONS.LOAD_MORE: {
			return {
				...state,
				data: [...state.data, ...action.payload],
				canLoadMore: !(action.payload.length < state.limit),
			};
		}

		case ACTIONS.LOAD: {
			return {
				...state,
				data: action.payload,
				canLoadMore: !(action.payload.length < state.limit),
			};
		}

		case ACTIONS.SET_CURRENT: {
			return {
				...state,
				current: action.payload,
			};
		}

		case ACTIONS.UPDATE: {
			state.data = state.data.map(t => (t._id === action.payload._id ? action.payload : t));
			return { ...state };
		}

		case ACTIONS.RESET: {
			return { ...initialState };
		}

		default:
			return state;
	}
}
