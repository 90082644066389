import styled from 'styled-components';
import { colors } from '../../utils/colors';

const Wrap = styled.div`
	.ring {
		display: inline-block;
		position: relative;

		&.xs {
			width: 28px;
			height: 28px;

			> div {
				width: 24px;
				height: 24px;
				border-width: 2px;
			}
		}

		&.s {
			width: 40px;
			height: 40px;

			> div {
				width: 32px;
				height: 32px;
				border-width: 2px;
			}
		}

		&.m {
			width: 60px;
			height: 60px;

			> div {
				width: 48px;
				height: 48px;
				border-width: 2px;
			}
		}

		&.l {
			width: 80px;
			height: 80px;

			> div {
				width: 64px;
				height: 64px;
				border-width: 2px;
			}
		}
	}
	.ring div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 64px;
		height: 64px;
		border: 2px solid ${colors['figc_gold']};
		border-radius: 50%;
		animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: ${colors['figc_gold']} transparent transparent transparent;
	}
	.ring div:nth-child(1) {
		animation-delay: -0.45s;
	}
	.ring div:nth-child(2) {
		animation-delay: -0.3s;s
	}
	.ring div:nth-child(3) {
		animation-delay: -0.15s;
	}
	@keyframes ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

const Loader = ({ size = 'm' }: { size?: 'xs' | 's' | 'm' | 'l' }) => {
	return (
		<Wrap>
			<div className={`ring ${size}`}>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</Wrap>
	);
};

export default Loader;
