import { TMessage } from './types/Message.type';
import socket from './socket.handler';
import { Subject, Observable } from 'rxjs';

class Eywa {
	// ------------------------------------------------
	// f i e l d s
	// ------------------------------------------------
	private static _instance: Eywa;
	private _messages: Subject<TMessage>;
	private _connection: Subject<boolean>;

	private socket: SocketIOClient.Socket;
	private uuid: string;

	// ------------------------------------------------
	// c o n s t r u c t o r
	// ------------------------------------------------
	private constructor(socket: SocketIOClient.Socket, uuid: string) {
		this.socket = socket;
		this.uuid = uuid;
		this._messages = new Subject();
		this._connection = new Subject();

		this.listeners();
	}

	// ------------------------------------------------
	// i n s t a n c e
	// ------------------------------------------------
	public static get Instance() {
		return this._instance;
	}

	// ------------------------------------------------
	// p u b l i c
	// ------------------------------------------------
	public static init(uuid: string): void {
		if (!this._instance) {
			console.log(`${Eywa.name} connecting...`);
			this._instance = new this(socket.connect(uuid), uuid);
		} else {
			console.log(`${Eywa.name} already connected`);
		}
	}

	public messages(): Observable<TMessage> {
		return this._messages.asObservable();
	}

	public connection(): Observable<boolean> {
		return this._connection.asObservable();
	}

	// ------------------------------------------------
	// p r i v a t e
	// ------------------------------------------------
	private listeners(): void {
		this.socket.on('connect_error', (err: any) => {
			console.error(`${Eywa.name} socket: error`, err);
		});

		this.socket.on('connect', () => {
			console.log(
				`${Eywa.name} socket: Connesso come ${this.socket.id} con uuid = ${this.uuid}`
			);
			this._connection.next(true);
		});

		this.socket.on('disconnect', () => {
			console.log(`${Eywa.name} socket: disconnesso.`);
			this._connection.next(false);
		});

		this.socket.on('msg', (message: TMessage) => {
			console.info(`${Eywa.name} message received`, message);
			this.notify(message);
		});
	}

	private notify(message: TMessage): void {
		this._messages.next(message);
	}
}

export default Eywa;
