type TProps = {
	children: React.ReactNode;
	level?: 1 | 2 | 3 | 4 | 5 | 6;
	style?: React.CSSProperties;
};

const styles: React.CSSProperties = {
	marginTop: 0,
	marginBottom: 0,
};

const Title = ({ level = 1, style = {}, ...props }: TProps) => {
	switch (level) {
		case 1: {
			return <h1 style={{ ...styles, ...style }}>{props.children}</h1>;
		}
		case 2: {
			return <h2 style={{ ...styles, ...style }}>{props.children}</h2>;
		}
		case 3: {
			return <h3 style={{ ...styles, ...style }}>{props.children}</h3>;
		}
		case 4: {
			return <h4 style={{ ...styles, ...style }}>{props.children}</h4>;
		}
		case 5: {
			return <h5 style={{ ...styles, ...style }}>{props.children}</h5>;
		}
		case 6: {
			return <h6 style={{ ...styles, ...style }}>{props.children}</h6>;
		}
	}
};

export default Title;
