import Typography from '../Typography';
import { ApplicantLogo } from './ApplicantLogo';
import { InviteManager } from './InviteManager';

type TProps = {
	club: {
		logo?: string;
		club_id: number;
		club_name: string;
		team_name: string;
		is_applicant: boolean;
	};
};

const ClubCard = ({ club }: TProps) => (
	<>
		<div className="logo-wrapper">
			{/* <div className="logo">{club.logo && <img alt={club.club_name} src={club.logo} />}</div> */}
			<ApplicantLogo isApplicant={club.is_applicant} />
		</div>
		<div className="club-name">
			<Typography.Title level={4}>{club.club_name}</Typography.Title>
			<Typography.Text>{club.team_name}</Typography.Text>
			<div className="invite-manager">
				<InviteManager club_id={club.club_id} />
			</div>
		</div>
	</>
);

export { ClubCard };
