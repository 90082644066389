import axios from '../interceptor';
import api from './api';
import { EDelegations, ERegions, TObjChampionship } from '@golee/gle-types';

export default {
	list: async (
		offset: number = 0,
		limit: number = 20,
		sort: string = '-createdAt',
		filters: {
			delegation?: EDelegations;
			region?: ERegions;
			is_draft?: boolean;
		} = {}
	): Promise<TObjChampionship[]> => {
		try {
			const res = await axios.get(api.list(), {
				params: {
					offset,
					limit,
					sort,
					...filters,
				},
			});
			return res.data;
		} catch (err) {
			throw err;
		}
	},

	getOne: async (_id: string): Promise<TObjChampionship> => {
		try {
			const res = await axios.get(api.single(_id));
			return res.data;
		} catch (err) {
			throw err;
		}
	},
};
