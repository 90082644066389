import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '../../../components/Typography';
import { TGlobalState } from '../../../redux/types';
import { TObjRichiestaAmichevole, ERichiestaAmichevoleStatus } from '@golee/gle-types';
import Table from '../../../components/@table/Table/Table';
import Semaphore from '../../../components/Semaphore';
import Layout from '../../../layout';
import { TeamTableCell } from '../../../components/TeamTableCell';
import { TColumn } from '../../../components/@table/Table';
import { Filters } from '../../../components/@table/Filters';
import { Search } from '../../../components/@table/Search';
import { ListViewProps } from '../../types';
import { Paginator } from '../../../components/@table/Pagination';

const View = ({ ...props }: ListViewProps) => {
	const messages = useSelector((store: TGlobalState) => store.Locale.messages);

	const _columns: TColumn<TObjRichiestaAmichevole>[] = [
		{
			title: messages['home'],
			id: 'home',
			accessor: row => {
				return <TeamTableCell team={row.home_team} />;
			},
			size: 2,
		},
		{
			title: messages['away'],
			id: 'away',
			accessor: row => {
				return <TeamTableCell team={row.away_team} />;
			},
			size: 2,
		},
		{
			title: messages['date'],
			id: 'match_date',
			accessor: 'match_date',
			sortable: true,
		},
		{
			title: messages['time'],
			id: 'match_time',
			accessor: 'match_time',
		},
		{
			title: messages['module'],
			id: 'document_url',
			accessor: row => {
				return (
					<>
						<i
							data-tip={
								row.document_url ? 'Visualizza modulo' : 'Modulo non disponibile'
							}
							data-effect="solid"
							className="las la-file-invoice"
							onClick={ev => {
								ev.stopPropagation();
								if (row.document_url) {
									window.open(row.document_url, '_blank');
								}
							}}></i>
						{/* <ReactTooltip /> */}
					</>
				);
			},
		},
		{
			title: messages['status'],
			id: 'status',
			sortable: true,
			accessor: row => {
				const renderSemaphore = (status: string) => {
					switch (status) {
						case ERichiestaAmichevoleStatus.APPROVED: {
							return (
								<Semaphore
									tooltip={messages[status.toLowerCase()]}
									className={'success'}
								/>
							);
						}
						case ERichiestaAmichevoleStatus.FORWARDED: {
							return (
								<Semaphore
									tooltip={messages[status.toLowerCase()]}
									className={'info'}
								/>
							);
						}
						case ERichiestaAmichevoleStatus.REJECTED: {
							return (
								<Semaphore
									tooltip={messages[status.toLowerCase()]}
									className={'danger'}
								/>
							);
						}
						case ERichiestaAmichevoleStatus.WAITING: {
							return (
								<Semaphore
									tooltip={messages[status.toLowerCase()]}
									className={'warning'}
								/>
							);
						}
					}
				};

				return <div id={`match-${row.ref_match}-friendly-request-status`}>{renderSemaphore(row.status)}</div>;
			},
		},
	];

	const columns = React.useMemo(() => _columns, []);

	const data = React.useMemo(() => props.data, [props.data]);

	return (
		<>
			<Layout.Header>
				<Typography.Title>{messages['friendly_match_requests']}</Typography.Title>
			</Layout.Header>

			<Layout.Content style={{ height: 'auto' }}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
						height: 30,
						gap: 20,
					}}>
					<Search
						placeholder={messages['search']}
						onChange={ev => props.onSearch && props.onSearch(ev)}
					/>

					<Filters
						filters={[
							{
								label: messages['status'],
								key: 'status',
								type: 'select',
								options: [
									ERichiestaAmichevoleStatus.WAITING,
									ERichiestaAmichevoleStatus.APPROVED,
									ERichiestaAmichevoleStatus.FORWARDED,
									ERichiestaAmichevoleStatus.REJECTED,
								].map(t => ({ label: messages[t.toLowerCase()], value: t })),
							},
						]}
						onFilter={props.onFilter}
					/>
				</div>

				<Table
					columns={columns}
					data={data}
					onRowClick={props.onRowClick}
					loading={props.loading}
					onSort={e => props.onSort && props.onSort(e?.stringified)}
					noDataMessage={'Nessun dato trovato'}
				/>

				{props.pagination && <Paginator {...props.pagination} />}
			</Layout.Content>
		</>
	);
};

export default View;
