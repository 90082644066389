import {
	ACTIONS,
	TActConnectionClosed,
	TActConnectionOpened,
	TActLoading,
	TActNew,
	TActRead,
	TActReadAll,
	TActSet,
	TNotification,
} from './types';

export const actConnectionOpened = (): TActConnectionOpened => {
	return {
		type: ACTIONS.CONNECTION_OPENED,
	};
};

export const actConnectionClosed = (): TActConnectionClosed => {
	return {
		type: ACTIONS.CONNECTION_CLOSED,
	};
};

export const actLoading = (): TActLoading => {
	return {
		type: ACTIONS.LOADING,
	};
};

export const actSet = (data: TNotification[], unread: number): TActSet => {
	return {
		type: ACTIONS.SET,
		payload: {
			data,
			unread,
		},
	};
};

export const actNew = (notification: TNotification): TActNew => {
	return {
		type: ACTIONS.NEW,
		payload: notification,
	};
};

export const actRead = (notification: TNotification, user: string): TActRead => {
	return {
		type: ACTIONS.READ,
		payload: {
			notification,
			user,
		},
	};
};

export const actReadAll = (user: string): TActReadAll => {
	return {
		type: ACTIONS.READ_ALL,
		payload: {
			user,
		},
	};
};
