import axios from './../interceptor';
import api from './api';

const service = {
	has_golee: async (club_id: number): Promise<any> => {
		try {
			const res = await axios.get(api.has_golee(club_id));
			return res.data;
		} catch (err) {
			throw err;
		}
	},
};

export default service;
