import { ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '../../utils/colors';

const Wrap = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	span {
		color: ${colors['primary']};
		font-size: 22px;
		width: 30px;
		height: 30px;
		margin-right: 10px;
		text-align: center;
		display: inline-block;
		line-height: 30px;
		border-radius: 50%;
	}
	&:hover {
		span {
			background: ${colors['light_grey_1']};
		}
		div {
			color: ${colors['primary']};
		}
	}
`;

const Icon = ({ onClick, text }: { onClick?: () => void; text?: ReactNode }) => {
	return (
		<Wrap onClick={onClick}>
			<span>
				<i className="la la-angle-left"></i>
			</span>
			<div>{text}</div>
		</Wrap>
	);
};

export default Icon;
