import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { TGlobalState } from '../../redux/types';
import { colors } from '../../utils/colors';

const Wrapper = styled.div`
	text-align: center;
	margin-top: 10px;
	cursor: pointer;
	position: relative;
	i {
		font-size: 28px;
		color: ${colors['light_grey']};
	}

	.counter {
		pointer-events: none;
		cursor: pointer;
		background-color: ${colors['figc_gold']};
		position: absolute;
		font-size: 10px;
		border-radius: 50%;
		width: 16px;
		height: 16px;
		color: ${colors['white']};
		text-align: center;
		line-height: 16px;
		top: -5px;
		right: 0;
		transition: transform 0.5s;
		will-change: transform;

		&.hidden {
			transform: scale(0);
		}

		&.visible {
			transform: scale(1);
		}
	}
`;

type TProps = {
	children?: React.ReactNode;
	id?: string;
	onClick?: () => void;
};

const Toggle = ({ children, ...props }: TProps) => {
	const { messages } = useSelector((state: TGlobalState) => state.Locale);

	const { unread } = useSelector((state: TGlobalState) => state.Notifications);

	// just to keep track of old state
	const [count, setCount] = useState<number>(unread);
	const [sound, setSound] = useState<any>(null);

	useEffect(() => {
		if (unread > count) {
			setCount(unread);
			if (sound) {
				try {
					sound.play();
				} catch (err) {}
			}
		}
	}, [unread]);

	useEffect(() => {
		setSound(new Audio('/sounds/notification.mp3'));
	}, []);

	return (
		<Wrapper id={props.id}>
			<div
				onClick={props.onClick}
				data-tip={
					unread > 0
						? `${unread} ${messages['unread_notifications']}`
						: messages['notifications']
				}
				data-effect="solid">
				<i className="las la-bell"></i>
			</div>

			<span className={`counter ${unread > 0 ? 'visible' : 'hidden'}`}>{unread}</span>
		</Wrapper>
	);
};

export default Toggle;
