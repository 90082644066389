import { TObjDelegation } from '@golee/gle-types';
import { DelegationsService } from '../../services/Matches';
import { actSetLoading, actSet, actSetCurrent } from './actions';

export const $loadDelegations = (onError?: (err: any) => void) => {
	return async (dispatch: any) => {
		dispatch(actSetLoading(true));

		try {
			const data: any[] = await DelegationsService.list();
			dispatch(actSet(data));
		} catch (err) {
			dispatch(actSetLoading(false));
			onError && onError(err);
		}
	};
};

export const $setCurrentDelegation = (delegation: TObjDelegation) => {
	return async (dispatch: any) => {
		dispatch(actSetCurrent(delegation));
	};
};
