import { Subject, Observable } from 'rxjs';
import { DataBridgeEvent } from './types';

export class DataBridge {
	// ------------------------------------------------
	// f i e l d s
	// ------------------------------------------------
	private static _instance: DataBridge;
	private _events: Subject<DataBridgeEvent>;

	// ------------------------------------------------
	// c o n s t r u c t o r
	// ------------------------------------------------
	private constructor() {
		this._events = new Subject();
	}

	// ------------------------------------------------
	// i n s t a n c e
	// ------------------------------------------------
	public static get Instance() {
		if (!this._instance) {
			this._instance = new this();
		}

		return this._instance;
	}

	// ------------------------------------------------
	// p u b l i c
	// ------------------------------------------------
	public events(): Observable<DataBridgeEvent> {
		return this._events.asObservable();
	}

	public dispatch(message: DataBridgeEvent): void {
		this._events.next(message);
	}
}
