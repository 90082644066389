import styled from 'styled-components';
import { colors } from '../../../utils/colors';

type TProps = {
	placeholder?: string;
	onChange: (ev: string | undefined) => void;
};

let timeout: any;

const Styled = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	height: 100%;

	#search-icon {
		cursor: pointer;
		stroke: ${colors.dark_grey_1};
		stroke-width: 1.1;
		fill: none;
		transform: scale(1.5);
		&.active {
			stroke: ${colors.primary};
		}
	}

	input {
		display: none;
		border: none;
		border-bottom: 1px solid #ddd;
		border-radius: 0;
		padding-left: 0;

		&.show {
			display: block;
		}
	}
`;

const Search = ({ ...props }: TProps) => {
	const onType = (value: string) => {
		if (timeout) {
			clearTimeout(timeout);
		}

		timeout = setTimeout(() => {
			if (value.length >= 3 || value.length == 0) {
				if (!value) {
					props.onChange(undefined);
				} else {
					props.onChange(value);
				}
			}
		}, 500);
	};

	return (
		<Styled>
			<input
				id="search"
				onChange={ev => onType(ev.target.value)}
				style={{ width: 200 }}
				placeholder={props.placeholder}
			/>

			<svg
				height="16"
				width="16"
				id="search-icon"
				onClick={() => {
					const input = document.getElementById('search');
					input?.classList.toggle('show');

					const icon = document.getElementById('search-icon');
					icon?.classList.toggle('active');
				}}>
				<circle cx="10" cy="6" r="5"></circle>
				<line x1="1" y1="15" x2="7" y2="9.5"></line>
			</svg>
		</Styled>
	);
};

export default Search;
