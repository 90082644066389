import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router/immutable';
import routes from './routes';
import { Dashboard } from '../pages/Dashboard';
import {
	FriendlyMatchRequestDetail,
	FriendlyMatchRequestsList,
} from '../pages/FriendlyMatchRequests';
import {
	MatchVariationRequestDetail,
	MatchVariationRequestsList,
} from '../pages/MatchVariationRequests';
import ModalRoute from './modal/ModalRoute';
import { ChampionshipsList } from '../pages/RefertiMatch/Championships';
import MatchReferti from '../pages/RefertiMatch/Referti';
import { MatchRefertiDetail } from '../pages/RefertiMatch/Referti/RefertiReceived';

const AppRouter = ({ history }: any) => {
	return (
		<>
			<ConnectedRouter history={history}>
				<Switch>
					<Route exact path={routes.DASHBOARD} component={Dashboard} />

					<Route
						path={routes.FRIENDLY_MATCH_REQUESTS}
						render={params => (
							<>
								<Route
									path={`${params.match.url}/`}
									component={FriendlyMatchRequestsList}
								/>
								<Route
									path={`${params.match.url}/:id`}
									component={(props: any) => (
										<ModalRoute
											component={<FriendlyMatchRequestDetail {...props} />}
											{...props}
										/>
									)}
								/>
							</>
						)}
					/>

					<Route
						path={routes.MATCH_VARIATIONS}
						render={params => (
							<>
								<Route
									path={`${params.match.url}/`}
									component={MatchVariationRequestsList}
								/>
								<Route
									path={`${params.match.url}/:id`}
									component={(props: any) => (
										<ModalRoute
											component={<MatchVariationRequestDetail {...props} />}
											{...props}
										/>
									)}
								/>
							</>
						)}
					/>

					<Route
						path={routes.CHAMPIONSHIPS}
						render={params => (
							<>
								<Route
									path={`${params.match.url}/`}
									exact
									component={ChampionshipsList}
								/>
								<Route
									path={`${params.match.url}/:id${routes.MATCH_REFERTI}`}
									render={subparams => (
										<>
											<Route
												path={`${subparams.match.url}/`}
												component={(props: any) => (
													<MatchReferti
														{...props}
														subparams={subparams}
													/>
												)}
											/>
											<Route
												path={`${subparams.match.url}/:id`}
												component={(props: any) => (
													<ModalRoute
														component={
															<MatchRefertiDetail {...props} />
														}
														{...props}
													/>
												)}
											/>
										</>
									)}
								/>
							</>
						)}
					/>
				</Switch>
			</ConnectedRouter>
		</>
	);
};

export default AppRouter;
