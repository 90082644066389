type Contents = {
	it: string;
	en: string;
};

export type TNotification = {
	_id: string;
	contents: Contents;
	createdAt: Date;
	type: string;
	icon?: string;
	metadata: any;
	recipients: any;
};

export type TStateNotifications = {
	data: TNotification[];
	unread: number;
	loading: boolean;
	canLoadMore: boolean;
	limit: number;
	connectionOpened: boolean;
};

export enum ACTIONS {
	CONNECTION_OPENED = 'CONNECTION_OPENED_NOTIFICATIONS',
	CONNECTION_CLOSED = 'CONNECTION_CLOSED_NOTIFICATIONS',
	LOADING = 'LOADING_NOTIFICATIONS',
	SET = 'SET_NOTIFICATIONS',
	NEW = 'NEW_NOTIFICATION',
	READ = 'READ_NOTIFICATION',
	READ_ALL = 'READ_ALL_NOTIFICATIONS',
}

export type TActConnectionOpened = {
	type: ACTIONS.CONNECTION_OPENED;
};

export type TActConnectionClosed = {
	type: ACTIONS.CONNECTION_CLOSED;
};

export type TActLoading = {
	type: ACTIONS.LOADING;
};

export type TActSet = {
	type: ACTIONS.SET;
	payload: {
		data: any[];
		unread: number;
	};
};

export type TActNew = {
	type: ACTIONS.NEW;
	payload: any;
};

export type TActRead = {
	type: ACTIONS.READ;
	payload: {
		notification: TNotification;
		user: any;
	};
};

export type TActReadAll = {
	type: ACTIONS.READ_ALL;
	payload: {
		user: any;
	};
};

export type TActions =
	| TActConnectionClosed
	| TActConnectionOpened
	| TActLoading
	| TActSet
	| TActNew
	| TActRead
	| TActReadAll;
