import { useSelector } from 'react-redux';
import { TGlobalState } from '../../redux/types';

type TProps = {
	isApplicant: boolean;
};

const ApplicantLogo = ({ isApplicant }: TProps): any => {
	const messages = useSelector((store: TGlobalState) => store.Locale.messages);

	return (
		isApplicant && (
			<div data-tip={messages['applicant']} data-effect="solid">
				<img src={require('./../../assets/applicant.png').default} alt="" />
			</div>
		)
	);
};

export { ApplicantLogo };
