import Wrapper from './components/Wrapper';
import Main from './components/Main';
import Sider from './components/Sider';
import Content from './components/Content';
import Header from './components/Header';

export default {
	Wrapper,
	Sider,
	Main,
	Content,
	Header,
};
