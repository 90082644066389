import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Subscription } from 'rxjs';
import toast from '../../../../../components/Toast/toast';
import Eywa from '../../../../../eywa/eywa';
import { DataContext, HttpDataProvider } from '../../../../../providers';
import { $loadSingleChampionship } from '../../../../../redux/Championships/middleware';
import {
	$setCurrentMatchReport,
	$updateMatchReport,
} from '../../../../../redux/MatchReports/middleware';
import { TGlobalState } from '../../../../../redux/types';
import routes from '../../../../../router/routes';
import { ReportsService } from '../../../../../services/Matches';
import View from './view';

const Container = ({ ...props }: any) => {
	const championship = useSelector((store: TGlobalState) => store.Championships.current);

	const championship_id = props.subparams.match.params.id;

	const dispatch = useDispatch();

	let subscription: Subscription;

	const attachToEywa = () => {
		subscription = Eywa.Instance.messages().subscribe(message => {
			switch (message.event) {
				case 'RefertoGaraRejectedEvent':
				case 'RefertoGaraApprovedEvent':
					dispatch($updateMatchReport(message.payload.referto_gara_obj));
					break;
				case 'RichiestaAmichevoleForwardedEvent':
					dispatch($updateMatchReport(message.payload.source_referto_gara_obj));
					break;
			}
		});
	};

	const detachFromEywa = () => {
		if (subscription) {
			subscription.unsubscribe();
		}
	};

	useEffect(() => {
		if (!championship && championship_id) {
			dispatch(
				$loadSingleChampionship(championship_id, () => {
					toast.error('Campionato non trovato');
				})
			);
		}
	}, [championship_id]);

	useEffect(() => {
		attachToEywa();

		return () => {
			detachFromEywa();
		};
	}, []);

	return (
		<>
			{championship && (
				<HttpDataProvider
					api={{
						function: ReportsService.list,
						params: {
							championship_id: championship._id,
						},
					}}>
					<DataContext.Consumer>
						{context => (
							<>
								<View
									championship={championship}
									data={context.data}
									loading={context.loading}
									onFilter={context.onFilter}
									onSearch={context.onSearch}
									onSort={context.onSort}
									pagination={context.pagination}
									onGoBack={() => {
										dispatch(push(`${routes.CHAMPIONSHIPS}`));
									}}
									onRowClick={item => {
										dispatch($setCurrentMatchReport(item));
										dispatch(
											push(
												props.subparams.location.pathname.replace(
													/match-referti.*/,
													`match-referti/${
														item._id
													}#${props.subparams.location.hash.substr(1)}`
												)
											)
										);
									}}
								/>
							</>
						)}
					</DataContext.Consumer>
				</HttpDataProvider>
			)}
		</>
	);
};

export default Container;
