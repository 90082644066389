import {
	actSetLoading,
	actLoadMore,
	actSetCurrent,
	actUpdate,
	actLoad,
	actSetOffset,
} from './actions';
import { ERefertoGaraStatus, TObjMatchReferto, TObjRefertoGara } from '@golee/gle-types';
import { TGlobalState } from '../types';
import { ReportsService } from '../../services/Matches';

export const $loadSingleMatchReport = (_id: string, onError?: (err: any) => void) => {
	return async (dispatch: any) => {
		dispatch(actSetLoading(true));

		try {
			const item: TObjRefertoGara = await ReportsService.getOne(_id);
			dispatch(actSetCurrent(item));
		} catch (err) {
			onError && onError(err);
		} finally {
			dispatch(actSetLoading(false));
		}
	};
};

export const $setCurrentMatchReport = (item: TObjRefertoGara) => {
	return async (dispatch: any, getState: any) => {
		dispatch(actSetCurrent(item));
	};
};

export const $approveMatchReport = (
	item: TObjRefertoGara,
	onError?: (err: any) => void,
	onSucces?: () => void
) => {
	return async (dispatch: any, getState: any) => {
		try {
			await ReportsService.approve(item._id);
			dispatch(actUpdate({ ...item, status: ERefertoGaraStatus.APPROVED }));
			onSucces && onSucces();
		} catch (err) {
			onError && onError(err);
		}
	};
};

export const $rejectMatchReport = (
	item: TObjRefertoGara,
	reason: string,
	onError?: (err: any) => void,
	onSucces?: () => void
) => {
	return async (dispatch: any, getState: any) => {
		try {
			await ReportsService.reject(item._id, reason);
			dispatch(
				actUpdate({
					...item,
					status: ERefertoGaraStatus.REJECTED,
					rejection_motivation: reason,
				})
			);
			onSucces && onSucces();
		} catch (err) {
			onError && onError(err);
		}
	};
};

export const $forwardMatchReport = (
	item: TObjRefertoGara,
	delegation: string,
	onError?: (err: any) => void,
	onSucces?: () => void
) => {
	return async (dispatch: any, getState: any) => {
		try {
			await ReportsService.forward(item._id, delegation);
			dispatch(
				actUpdate({
					...item,
					status: ERefertoGaraStatus.FORWARDED,
				})
			);
			onSucces && onSucces();
		} catch (err) {
			onError && onError(err);
		}
	};
};

export const $updateMatchReport = (item: TObjRefertoGara) => {
	return async (dispatch: any, getState: any) => {
		dispatch(actUpdate({ ...item }));
	};
};
