import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Typography from '../../../components/Typography';
import { TGlobalState } from '../../../redux/types';
import { ERichiestaVariazioneStatus, TObjRichiestaVariazione } from '@golee/gle-types';
import Semaphore from '../../../components/Semaphore';
import ReactTooltip from 'react-tooltip';
import Layout from '../../../layout';
import { TeamTableCell } from '../../../components/TeamTableCell';
import { Table, TColumn } from '../../../components/@table/Table';
import { Search } from '../../../components/@table/Search';
import { Filters } from '../../../components/@table/Filters';
import { ListViewProps } from '../../types';
import { Paginator } from '../../../components/@table/Pagination';

const mapStatusToClassName = {
	[ERichiestaVariazioneStatus.APPROVED]: 'success',
	[ERichiestaVariazioneStatus.FORWARDED]: 'info',
	[ERichiestaVariazioneStatus.REJECTED]: 'danger',
	[ERichiestaVariazioneStatus.WAITING]: 'warning',
};

const View = ({ ...props }: ListViewProps) => {
	const messages = useSelector((store: TGlobalState) => store.Locale.messages);

	const _columns: TColumn<TObjRichiestaVariazione>[] = [
		{
			title: messages['home'],
			id: 'home_team',
			accessor: row => {
				return <TeamTableCell team={row.home_team} />;
			},
			size: 2,
			sortable: true,
		},
		{
			title: messages['away'],
			id: 'away_team',
			accessor: row => {
				return <TeamTableCell team={row.away_team} />;
			},
			size: 2,
		},
		{
			title: messages['round'],
			id: 'girone',
			accessor: row => {
				// TODO: return row.girone.name
				return '-';
			},
		},
		{
			title: messages['day'],
			id: 'step',
			sortable: true,
			accessor: row => {
				return row.step || '-';
			},
		},
		{
			title: messages['date'],
			id: 'match_date',
			accessor: 'match_date',
			sortable: true,
		},
		{
			title: messages['time'],
			id: 'match_time',
			accessor: 'match_time',
			sortable: true,
		},
		{
			title: messages['module'],
			id: 'document_url',
			accessor: row => {
				return (
					<>
						<i
							data-tip={
								row.document_url ? 'Visualizza modulo' : 'Modulo non disponibile'
							}
							data-effect="solid"
							className="las la-file-invoice"
							onClick={ev => {
								ev.stopPropagation();
								if (row.document_url) {
									window.open(row.document_url, '_blank');
								}
							}}></i>
						<ReactTooltip />
					</>
				);
			},
		},
		{
			title: messages['status'],
			id: 'status',
			sortable: true,
			accessor: row => (
				<Semaphore
					tooltip={messages[row.status.toLowerCase()]}
					//@ts-ignore
					className={mapStatusToClassName[row.status]}
				/>
			),
		},
		{
			title: messages['opponent_club'],
			id: 'opponent_club',
			accessor: row => (
				<Semaphore
					tooltip={`
					${messages[`status.${row.other_club_status && row.other_club_status.toLowerCase()}`]}
			`}
					//@ts-ignore
					className={mapStatusToClassName[row.other_club_status]}
				/>
			),
		},
	];

	const columns = React.useMemo(() => _columns, []);

	const data = React.useMemo(() => props.data, [props.data]);

	return (
		<>
			<Layout.Header>
				<Typography.Title>{messages['match_variation_requests']}</Typography.Title>
			</Layout.Header>

			<Layout.Content style={{ height: 'auto' }}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
						height: 30,
						gap: 20,
					}}>
					<Search
						placeholder={'Cerca...'}
						onChange={ev => props.onSearch && props.onSearch(ev)}
					/>

					<Filters
						filters={[
							{
								label: messages['status'],
								key: 'status',
								type: 'select',
								options: [
									ERichiestaVariazioneStatus.WAITING,
									ERichiestaVariazioneStatus.APPROVED,
									ERichiestaVariazioneStatus.FORWARDED,
									ERichiestaVariazioneStatus.REJECTED,
								].map(t => ({ label: messages[t.toLowerCase()], value: t })),
							},
						]}
						onFilter={props.onFilter}
					/>
				</div>

				<Table
					columns={columns}
					data={data}
					onRowClick={props.onRowClick}
					loading={props.loading}
					noDataMessage={'Nessun dato trovato'}
					onSort={e => props.onSort && props.onSort(e?.stringified)}
				/>

				{props.pagination && <Paginator {...props.pagination} />}
			</Layout.Content>
		</>
	);
};

export default View;
