import { TObjChampionship } from '@golee/gle-types';
import { ACTIONS, TActLoading, TActSet, TActSetCurrent, TActLoad } from './types';

export const actSetLoading = (status: boolean): TActLoading => {
	return {
		type: ACTIONS.SET_LOADING,
		payload: status,
	};
};

export const actLoad = (data: TObjChampionship[]): TActLoad => {
	return {
		type: ACTIONS.LOAD,
		payload: data,
	};
};

export const actSet = (data: TObjChampionship[]): TActSet => {
	return {
		type: ACTIONS.SET,
		payload: data,
	};
};

export const actSetCurrent = (data: TObjChampionship): TActSetCurrent => {
	return {
		type: ACTIONS.SET_CURRENT,
		payload: data,
	};
};
