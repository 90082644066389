import { TStateLocale, LocaleAction, ACTIONS } from './types';

export const getBrowserLang = (): string => {
	if (navigator.language.split(/[-_]/)[0] == 'it') return 'it';
	if (navigator.language.split(/[-_]/)[0] == 'en') return 'en';

	return 'it';
};

const initialState: TStateLocale = {
	language: getBrowserLang(),
	messages: {},
};

export default function languageReducer(state = initialState, action: LocaleAction): TStateLocale {
	switch (action.type) {
		case ACTIONS.LOAD_LANGUAGE: {
			return {
				...state,
				language: action.payload.language,
				messages: action.payload.messages,
			};
		}

		case ACTIONS.CHANGE_LANGUAGE:
			return {
				...state,
				language: action.payload.language,
				messages: action.payload.messages,
			};

		default:
			return state;
	}
}
