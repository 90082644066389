import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from '../../../../../components/Toast/toast';
import { DataContext, HttpDataProvider } from '../../../../../providers';
import { $loadSingleChampionship } from '../../../../../redux/Championships/middleware';
import { TGlobalState } from '../../../../../redux/types';
import routes from '../../../../../router/routes';
import { MissingReportsService } from '../../../../../services';
import View from './view';

const Container = ({ ...props }: any) => {
	const championship = useSelector((store: TGlobalState) => store.Championships.current);

	const championship_id = props.subparams.match.params.id;

	const dispatch = useDispatch();

	useEffect(() => {
		if (!championship && championship_id) {
			dispatch(
				$loadSingleChampionship(championship_id, () => {
					toast.error('Campionato non trovato');
				})
			);
		}
	}, [championship_id]);

	return (
		<>
			{championship && (
				<HttpDataProvider
					api={{
						function: MissingReportsService.list,
						params: {
							championship_id: championship._id,
						},
					}}>
					<DataContext.Consumer>
						{context => (
							<>
								<View
									championship={championship}
									data={context.data}
									loading={context.loading}
									onFilter={context.onFilter}
									onSearch={context.onSearch}
									onSort={context.onSort}
									pagination={context.pagination}
									onGoBack={() => {
										dispatch(push(`${routes.CHAMPIONSHIPS}`));
									}}
								/>
							</>
						)}
					</DataContext.Consumer>
				</HttpDataProvider>
			)}
		</>
	);
};

export default Container;
