import { HermesService } from '../../services/Hermes';
import {
	actConnectionClosed,
	actConnectionOpened,
	actLoading,
	actNew,
	actRead,
	actReadAll,
	actSet,
} from './actions';
import { TNotification } from './types';

export const $loadNotifications = (auth0_id: string, offset: number, limit: number) => {
	return async (dispatch: any, getState: any) => {
		dispatch(actLoading());

		try {
			const data = await HermesService.history(auth0_id, offset, limit);
			const count = await HermesService.unreadCount(auth0_id);
			const _ = getState().Notifications.data;

			dispatch(actSet([..._, ...data], count));
		} catch (err) {}
	};
};

export const $newNotification = (data: TNotification) => {
	return async (dispatch: any) => {
		dispatch(actNew(data));
	};
};

export const $readNotification = (notification: TNotification, auth0_id: string) => {
	return async (dispatch: any) => {
		try {
			await HermesService.read(auth0_id, notification._id);
			dispatch(actRead(notification, auth0_id));
		} catch (err) {}
	};
};

export const $readAllNotifications = (auth0_id: string) => {
	return async (dispatch: any) => {
		try {
			await HermesService.readAll(auth0_id);
			dispatch(actReadAll(auth0_id));
		} catch (err) {}
	};
};

export const $notificationsConnectionOpened = () => {
	return async (dispatch: any) => {
		dispatch(actConnectionOpened());
	};
};

export const $notificationsConnectionClosed = () => {
	return async (dispatch: any) => {
		dispatch(actConnectionClosed());
	};
};
