import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store, history } from './redux/store';
import { Auth0Provider } from '@auth0/auth0-react';

import App from './App';
import './style.css';

declare global {
	interface Window {
		_env_: any;
	}
}

const current_version = require('../package.json').version;
const browser_app_version = localStorage.getItem('app_version');
if (browser_app_version !== current_version) {
	console.log(
		`current_version: ${current_version} !== browser_app_version: ${browser_app_version} => UPDATE VERSION`
	);
	localStorage.setItem('app_version', current_version);
}

const app_version = require('../package.json').version;

console.log(`Current app version: ${app_version}`);

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Auth0Provider
				audience={window._env_.REACT_APP_AUTH0_AUDIENCE}
				scope={window._env_.REACT_APP_AUTH0_SCOPE}
				domain={window._env_.REACT_APP_AUTH0_DOMAIN!}
				clientId={window._env_.REACT_APP_AUTH0_CLIENTID!}
				redirectUri={window.location.origin}>
				<App />
			</Auth0Provider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
