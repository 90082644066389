import axios from '../interceptor';
import api from './api';
import { TObjMatch } from '@golee/gle-types';
import { ApiQueryParams } from '../../types';

export default {
	list: async ({
		filters,
		...params
	}: ApiQueryParams & { championship_id: string }): Promise<TObjMatch[]> => {
		try {
			const res = await axios.get(api.list(params.championship_id), {
				params: {
					...params,
					...filters,
				},
			});
			return res.data;
		} catch (err) {
			throw err;
		}
	},

	count: async (championship_id: string): Promise<number> => {
		try {
			const res = await axios.get(api.count(championship_id));
			return res.data;
		} catch (err) {
			throw err;
		}
	},
};
