import styled from 'styled-components';

const Style = styled.div`
	overflow: hidden;
`;

type TProps = {
	children?: any;
};

const Main = ({ children, ...props }: TProps) => {
	return <Style>{children}</Style>;
};

export default Main;
