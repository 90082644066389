import styled from 'styled-components';
import { colors } from '../../utils/colors';

export const MenuParent = styled.ul`
	margin: 0px 0 0 0;
	list-style: none;
	padding: 0;
`;

export const MenuChild = styled.li`
	&:not(:last-child) {
		border-bottom: 1px solid ${colors['primary_light_1']};
	}

	> ul {
		margin-left: 20px;
		overflow: hidden;
		transition: max-height 0.4s;
	}

	&.selected {
		> a {
			color: #fff;
		}

		> ul {
			max-height: auto;
		}

		:not(.extendable) {
			> a {
				background-color: ${colors['primary_dark_1']};
			}
		}
	}

	&:not(.selected) {
		a {
			color: #fff;
		}
		> ul {
			max-height: 0;
		}

		&:hover {
			> a {
				background-color: ${colors['primary_dark']};
			}
		}
	}
`;

export const MenuLink = styled.a`
	cursor: pointer;
	padding: 12px;
	display: flex;
	align-items: center;
	text-transform: none;

	i {
		margin-right: 10px;
		font-size: 20px;
	}
`;
