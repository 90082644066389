import { TObjRefertoGara } from '@golee/gle-types';
import {
	ACTIONS,
	TActAddFilter,
	TActLoad,
	TActLoading,
	TActLoadMore,
	TActReset,
	TActSetCurrent,
	TActSetFilters,
	TActSetOffset,
	TActSetSort,
	TActUpdate,
} from './types';

export const actSetLoading = (status: boolean): TActLoading => {
	return {
		type: ACTIONS.SET_LOADING,
		payload: status,
	};
};

export const actLoad = (data: TObjRefertoGara[]): TActLoad => {
	return {
		type: ACTIONS.LOAD,
		payload: data,
	};
};

export const actLoadMore = (data: TObjRefertoGara[]): TActLoadMore => {
	return {
		type: ACTIONS.LOAD_MORE,
		payload: data,
	};
};

export const actSetCurrent = (item: TObjRefertoGara): TActSetCurrent => {
	return {
		type: ACTIONS.SET_CURRENT,
		payload: item,
	};
};

export const actUpdate = (data: TObjRefertoGara): TActUpdate => {
	return {
		type: ACTIONS.UPDATE,
		payload: data,
	};
};

export const actSetSort = (data: any): TActSetSort => {
	return {
		type: ACTIONS.SET_SORT,
		payload: data,
	};
};

export const actSetFilters = (data: any): TActSetFilters => {
	return {
		type: ACTIONS.SET_FILTERS,
		payload: data,
	};
};

export const actAddFilter = (data: any): TActAddFilter => {
	return {
		type: ACTIONS.ADD_FILTER,
		payload: data,
	};
};

export const actSetOffset = (data: number): TActSetOffset => {
	return {
		type: ACTIONS.SET_OFFSET,
		payload: data,
	};
};

export const actReset = (): TActReset => {
	return {
		type: ACTIONS.RESET,
	};
};
