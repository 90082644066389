import { ACTIONS, TActions, TStateChampionships } from './types';

const initialState: TStateChampionships = {
	data: [],
	loading: false,
	canLoadMore: true,
	offset: 0,
	limit: 20,
	current: undefined,
};

export default function appReducer(state = initialState, action: TActions): TStateChampionships {
	switch (action.type) {
		case ACTIONS.SET_LOADING: {
			return {
				...state,
				loading: action.payload,
			};
		}

		case ACTIONS.SET: {
			return {
				...state,
				data: [...state.data, ...action.payload],
				loading: false,
				offset: state.offset + state.limit,
				canLoadMore: !(action.payload.length < state.limit),
			};
		}

		case ACTIONS.LOAD: {
			return {
				...state,
				data: action.payload,
				loading: false,
				offset: state.offset + state.limit,
				canLoadMore: !(action.payload.length < state.limit),
			};
		}

		case ACTIONS.SET_CURRENT: {
			return {
				...state,
				current: action.payload,
			};
		}

		default:
			return state;
	}
}
