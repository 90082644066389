import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { colors } from '../../utils/colors';

type TProps = {
	style?: React.CSSProperties;
	className?: 'success' | 'info' | 'warning' | 'danger';
	tooltip?: string;
};

const Wrap = styled.span`
	border-radius: 50%;
	width: 18px;
	height: 18px;
	display: inline-block;

	&.success {
		background-color: ${colors['success']};
	}
	&.info {
		background-color: ${colors['info']};
	}
	&.warning {
		background-color: ${colors['warning']};
	}
	&.danger {
		background-color: ${colors['danger']};
	}
`;

const Semaphore = ({ style = {}, ...props }: TProps) => {
	return (
		<>
			<Wrap data-tip={props.tooltip} data-effect="solid" className={props.className} style={style}></Wrap>
			<ReactTooltip />
		</>
	);
};

export default Semaphore;
