export type TStateLocale = {
	language: string;
	messages: any;
};

export enum ACTIONS {
	CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
	LOAD_LANGUAGE = 'LOAD_LANGUAGE',
}

export interface ChangeLanguageAction {
	payload: TStateLocale;
	type: ACTIONS.CHANGE_LANGUAGE;
}

export interface LoadLanguageAction {
	payload: TStateLocale;
	type: ACTIONS.LOAD_LANGUAGE;
}

export type LocaleAction = ChangeLanguageAction | LoadLanguageAction;
