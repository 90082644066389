import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from './redux/store';
import EywaListener from './eywa/eywa.listener';
import AppRouter from './router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { TGlobalState } from './redux/types';
import authAction from './redux/Auth/actions';
import Layout from './layout';
import ReactTooltip from 'react-tooltip';
import { useAuth0 } from '@auth0/auth0-react';
import { $loadDelegations, $setCurrentDelegation } from './redux/Delegations/middleware';
import Loaders from './components/Loaders';
import { TObjDelegation } from '@golee/gle-types';
import { PathUrl } from './classes/PathUrl/PathUrl.class';
import MixPanel from './helpers/MixPanel';

MixPanel.init();

const App = () => {
	const {
		isLoading,
		logout,
		isAuthenticated,
		loginWithRedirect,
		user,
		getIdTokenClaims,
		getAccessTokenSilently,
	} = useAuth0();

	const dispatch = useDispatch();

	const { messages } = useSelector((state: TGlobalState) => state.Locale);
	const { login_error, login_completed, user_permissions } = useSelector(
		(state: TGlobalState) => state.Auth
	);

	const { eywaInitialized } = useSelector((state: TGlobalState) => state.App);

	const delegations = useSelector((state: TGlobalState) => state.Delegations.data);
	const delegation = useSelector((state: TGlobalState) => state.Delegations.current);

	const handleAuth = async () => {
		if (!isLoading) {
			if (!isAuthenticated) {
				loginWithRedirect();
			} else {
				let token = await getAccessTokenSilently();
				const _ = await getIdTokenClaims();
				let georgian_token = _ && _['__raw'];

				dispatch(authAction.afterAuth0({ user, georgian_token, token }, true));
			}
		}
	};

	useEffect(() => {
		handleAuth();
	}, [isLoading]);

	useEffect(() => {
		if (login_error === 'SAGA_ERROR') {
			logout({ returnTo: window.location.origin });
		}
	}, [login_error]);

	useEffect(() => {
		if (login_completed) {
			dispatch($loadDelegations());
			history.listen(location => {
				const path_url = new PathUrl(location.pathname + location.hash);
				if (!!path_url.area) {
					MixPanel.trackEvent('Page view', path_url);
				}
			});
		}
	}, [login_completed]);

	useEffect(() => {
		if (delegation) {
			return;
		}

		if (delegations.length && user_permissions) {
			const { limitations } = user_permissions;

			let _delegation: TObjDelegation | undefined;

			if (limitations.region) {
				_delegation = delegations.find(
					t => t.region === limitations.region[0] && t.type === 'REG'
				);
				if (_delegation) {
					dispatch($setCurrentDelegation(_delegation));
				}
				return;
			}

			if (limitations.delegation) {
				_delegation = delegations.find(
					t => t.province === limitations.delegation[0] && t.type === 'PRO'
				);
				if (_delegation) {
					dispatch($setCurrentDelegation(_delegation));
				}
				return;
			}
		}
	}, [delegations, user_permissions]);

	return (
		<>
			<EywaListener>
				{!isLoading &&
				Object.keys(messages).length &&
				login_completed &&
				eywaInitialized ? (
					<Layout.Wrapper>
						<Layout.Sider />
						<Layout.Main>
							<AppRouter history={history} />
						</Layout.Main>
						<ReactTooltip />
					</Layout.Wrapper>
				) : (
					<Loaders.FullLoader />
				)}
			</EywaListener>
			<ToastContainer />
		</>
	);
};

export default App;
