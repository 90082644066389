import io from 'socket.io-client';

export default {
	connect: (uuid: string): SocketIOClient.Socket => {
		const SOCKET_URI = `https://${
			new URL(window._env_.REACT_APP_API_URL_EYWA as string).hostname
		}`;

		const SOCKET_PATH = `${
			new URL(window._env_.REACT_APP_API_URL_EYWA as string).pathname
		}socket.io`;

		const socket = io(SOCKET_URI, {
			reconnectionDelayMax: 10000,
			path: SOCKET_PATH,
			transportOptions: {
				polling: {
					extraHeaders: {
						'x-gle-client': uuid,
					},
				},
			},
		});

		return socket;
	},
};
