import styled from 'styled-components';
import { colors } from '../../../utils/colors';

const Styles = styled.div`
	overflow-x: auto;
	height: 100%;
	width: 100%;

	table {
		border-collapse: collapse;
		width: 100%;
		border-spacing: 0;
		min-width: 800px;
		display: flex;
		flex-flow: column;
		height: 100%;
		width: 100%;
		overflow: hidden;

		thead {
			flex: 0 0 auto;
			width: calc(100% - 0.9em); /* size of the scrollbar */

			tr {
				display: grid;

				th {
					display: flex;
					align-items: center;
				}
			}
		}

		tbody {
			flex: 1 1 auto;
			display: block;
			overflow-y: scroll;

			tr {
				display: grid;
			}
		}

		thead {
			tr {
				border-bottom: 1px solid ${colors['light_grey']};

				th {
					flex-grow: 1;
					padding: 10px;
					text-align: left;
					p {
						color: ${colors['dark_grey_1']};
						margin: 0;
						font-weight: 400;
					}
				}
			}
		}

		tr {
			align-items: center;
			border-bottom: 1px solid ${colors['light_grey']};

			:last-child {
				td {
					border-bottom: 0;
				}
			}

			&.hoverable {
				cursor: pointer;
				&:hover {
					background-color: ${colors['light_grey_2']};
				}
			}

			&.loader {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 5px 10px;
				border-bottom: none;

				td {
					width: 100%;
					padding-bottom: 0;
				}
			}

			&.empty-row {
				text-align: center;
				color: ${colors['dark_grey_1']};
			}

			td {
				margin: 0;
				padding: 10px;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				height: 30px;
				align-items: center;
				display: flex;

				> div {
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}

				:last-child {
					border-right: 0;
				}
			}
		}
	}

	.pagination {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 40px 0;
	}
`;

export default Styles;
