import axios from './interceptor';
import api from './api';

type TData = {
	from: string;
	to: [string];
	templateId: string;
	dynamicTemplateData?: any;
};

const _ = {
	sendEmailWithTemplate: async (data: TData): Promise<string> => {
		try {
			const res = await axios.post(api.sendEmailWithTemplate(), data);

			return res.data.url;
		} catch (err) {
			throw err;
		}
	},
	setup: async (auth0_user_id: string) => {
		try {
			await axios.post(api.setup(), { auth0_user: auth0_user_id });
		} catch (err) {
			throw err;
		}
	},
	history: async (auth0_user_id: string, start: number = 0, limit: number = 20) => {
		try {
			const res = await axios.get(api.history(auth0_user_id), { params: { start, limit } });
			return res.data;
		} catch (err) {
			throw err;
		}
	},

	unreadCount: async (auth0_user_id: string) => {
		try {
			const res = await axios.get(api.unreadCount(auth0_user_id));
			return res.data;
		} catch (err) {
			throw err;
		}
	},

	read: async (auth0_user_id: string, notification_id: string) => {
		try {
			const res = await axios.post(api.read(), {
				user: auth0_user_id,
				notification: notification_id,
			});
			return res.data;
		} catch (err) {
			throw err;
		}
	},

	readAll: async (auth0_user_id: string) => {
		try {
			const res = await axios.post(api.readAll(), {
				user: auth0_user_id,
			});
			return res.data;
		} catch (err) {
			throw err;
		}
	},
};

export default _;
