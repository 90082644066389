import styled from 'styled-components';

const Style = styled.div`
	display: grid;
	grid-template-columns: 300px auto;
	height: 100vh;
	overflow: hidden;

	&.sider-closed {
		grid-template-columns: 100px auto;

		.profile {
			* {
				display: none !important;
			}
		}

		.menu ul li a span {
			display: none;
		}

		.sidebar {
			width: 48px;
		}
	}
`;

type TProps = {
	children?: any;
};

const Wrapper = ({ children, ...props }: TProps) => {
	return <Style id="application-wrapper">{children}</Style>;
};

export default Wrapper;
