import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { ACTIONS as AUTH_ACTIONS } from './types';
import * as uuid from 'short-uuid';
import { GuardianInterceptor, GuardianService } from '../../services/Guardian';
import { MatchesInterceptor } from '../../services/Matches';
import { PeopleInterceptor } from '../../services/People';
import { HermesInterceptor } from '../../services/Hermes';
import { DocumentsInterceptor } from '../../modules/@documents';
import mixpanel from 'mixpanel-browser';

export enum EAccountRole {
	figc_clerk_regional = 'figc_clerk_regional',
	figc_clerk_provincial = 'figc_clerk_provincial',
}

const interceptors = [
	GuardianInterceptor,
	MatchesInterceptor,
	PeopleInterceptor,
	HermesInterceptor,
	DocumentsInterceptor,
];

export function* afterAuth0Login() {
	yield takeEvery(AUTH_ACTIONS.AFTER_AUTH0_LOGIN, function* (action: any) {
		try {
			var { georgian_token, token, user } = action.payload;
			mixpanel && mixpanel.identify(user.sub);

			if (georgian_token && token) {
				// @ts-ignore
				const resp_userperm: any = yield GuardianService.getUserPermissions(user.sub);

				if (resp_userperm.status == 200) {
					const userpermission = resp_userperm.data
						? resp_userperm.data.find((userperm: any) =>
								Object.keys(EAccountRole).includes(userperm.role)
						  )
						: null;

					// ------------------------------------------------
					// Check ruoli
					// ------------------------------------------------
					if (
						!userpermission ||
						![
							EAccountRole.figc_clerk_provincial,
							EAccountRole.figc_clerk_regional,
						].includes(userpermission.role)
					) {
						throw new Error(`Role not allowed to this client.`);
					}

					yield put({
						type: AUTH_ACTIONS.SETPERMISSIONFEATURES,
						payload: userpermission,
					});

					user = {
						...user,
						role: {
							role_id: userpermission.role_id,
							role: userpermission.role,
							limitations: userpermission.limitations,
						},
					};

					const client_id = uuid.generate();

					// Setto headers
					const setGoleeHeaderToAxios = (listAxios: any) => {
						listAxios.forEach((ax: any) => {
							ax.defaults.headers.common['Authorization'] = `Bearer ${token}`;
							ax.defaults.headers.common['x-gle-role'] = user.role.role_id;
							ax.defaults.headers.common['x-gle-client'] = client_id;
						});
					};

					setGoleeHeaderToAxios(interceptors);
					mixpanel && mixpanel.track('Log in');
					// set Auth store
					yield put({
						type: AUTH_ACTIONS.LOGIN_SUCCESS,
						georgian_token,
						token,
						client_id,
						login_completed: true,
						profile: { ...user },
					});
				} else {
					const err = 'Fallita chiamata resp_userperm';
					console.log('Login Error', err);
					yield put({ type: AUTH_ACTIONS.LOGIN_ERROR, error: 'SAGA_ERROR' });
				}
			} else {
				const err = '(georgian_token && token) return false';
				console.log('Login Error', err, georgian_token, token);
				yield put({ type: AUTH_ACTIONS.LOGIN_ERROR, error: 'SAGA_ERROR' });
			}
		} catch (err) {
			console.log('Login Error', err);
			yield put({ type: AUTH_ACTIONS.LOGIN_ERROR, error: 'SAGA_ERROR' });
		}
	});
}

export function* loginError() {
	yield takeEvery(AUTH_ACTIONS.LOGIN_ERROR, function* () {});
}

export default function* rootSaga() {
	yield all([fork(afterAuth0Login), fork(loginError)]);
}
