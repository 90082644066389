import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Eywa from './eywa';
import { Subscription } from 'rxjs';
import { TGlobalState } from '../redux/types';
import { $updateStatus } from '../redux/App/middleware';

const EywaListener = ({ ...props }: any) => {
	const { client_id } = useSelector((state: TGlobalState) => state.Auth);
	const dispatch = useDispatch();

	useEffect(() => {
		if (client_id) {
			Eywa.init(client_id);
			dispatch($updateStatus({ eywaInitialized: true }));

			attachToEywa();
			return () => {
				detachFromEywa();
			};
		}
	}, [client_id]);

	let connectionSub: Subscription;

	const attachToEywa = () => {
		connectionSub = Eywa.Instance.connection().subscribe(status => {
			dispatch($updateStatus({ connectedToEywa: !!status }));
		});
	};

	const detachFromEywa = () => {
		if (connectionSub) {
			connectionSub.unsubscribe();
		}
	};

	return <>{props.children}</>;
};

export default EywaListener;
