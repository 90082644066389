import { useEffect, useState } from 'react';
import { ReportsService } from '../../../../../services/Matches';
import Counter from '../Counter'

type TProps = {
	ref_championship: string;
	ref_girone?: string;
};

const RefertiCounter = ({ ref_championship, ref_girone, ...props }: TProps) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [count, setCount] = useState<number>(0);

	const loadCounter = async (): Promise<void> => {
		setIsLoading(true);
		try {
			const data = await ReportsService.count({
				ref_championship,
				ref_girone,
				status: 'WAITING',
			});
			setCount(data);
		} catch (err) {
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (ref_championship) {
			loadCounter();
		}
	}, [ref_championship]);

	return (
		<Counter isLoading={isLoading} count={count}/>
	);
};

export default RefertiCounter;
