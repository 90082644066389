import axios from './../interceptor';
import api from './api';
import { TObjDelegation } from '@golee/gle-types';

export default {
	list: async (): Promise<TObjDelegation[]> => {
		try {
			const res = await axios.get(api.list(), { params: { no_paginate: true } });
			return res.data;
		} catch (err) {
			throw err;
		}
	},
};
