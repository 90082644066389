import Typography from '../../../Typography';

type TProps = {
	label: string;
	value?: number | string | undefined;
	options?: {
		value: string | number | undefined;
		label: string;
	}[];
	onChange: (ev: string | undefined) => void;
};

const Select = ({ ...props }: TProps) => {
	return (
		<div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
			<Typography.Text>{props.label}</Typography.Text>
			<select
				value={props.value || ""}
				onChange={ev => props.onChange(ev.target.value ? ev.target.value : undefined)}>
				<option></option>
				{props.options?.map(t => (
					<option value={t.value}>{t.label}</option>
				))}
			</select>
		</div>
	);
};

export default Select;
