import { TObjChampionship, EDelegations, ERegions } from '@golee/gle-types';
import { ChampionshipService } from '../../services/Matches/Championships';
import { TGlobalState } from '../types';
import { actSetLoading, actSet, actSetCurrent, actLoad } from './actions';

export const $loadChampionships = (
	filters?: {
		delegation?: EDelegations;
		region?: ERegions;
	},
	sort?: string,
	reset?: boolean,
	onError?: (err: any) => void
) => {
	return async (dispatch: any, getState: () => TGlobalState) => {
		let { offset, limit, canLoadMore } = getState().Championships;

		if (!reset) {
			if (!canLoadMore) {
				return;
			}
		} else {
			offset = 0;
			dispatch(actLoad([]));
		}

		dispatch(actSetLoading(true));

		try {
			const data: any[] = await ChampionshipService.list(offset, limit, sort, {
				...filters,
				is_draft: false,
			});

			if (reset) {
				dispatch(actLoad(data));
			} else {
				dispatch(actSet(data));
			}
		} catch (err) {
			onError && onError(err);
		} finally {
			dispatch(actSetLoading(false));
		}
	};
};

export const $loadSingleChampionship = (_id: string, onError?: (err: any) => void) => {
	return async (dispatch: any, getState: () => TGlobalState) => {
		dispatch(actSetLoading(true));

		try {
			const item: TObjChampionship = await ChampionshipService.getOne(_id);

			dispatch(actSetCurrent(item));
		} catch (err) {
			onError && onError(err);
		} finally {
			dispatch(actSetLoading(false));
		}
	};
};

export const $setCurrentChampionship = (championship: TObjChampionship) => {
	return async (dispatch: any) => {
		dispatch(actSetCurrent(championship));
	};
};
