import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { TGlobalState } from '../../../redux/types';
import { colors } from '../../../utils/colors';
import Button from '../../Button';
import Typography from '../../Typography';
import { Checkbox, Select } from './Components';

type TProps = {
	filters: {
		label: string;
		type: 'select' | 'checkbox';
		key: string;
		options?: {
			value: string | number | undefined;
			label: string;
		}[];
	}[];
	onFilter?: (filters: { [key: string]: any }) => void;
};

const Styled = styled.div`
	.filter-icon {
		outline: none;
		stroke: ${colors.dark_grey_1};
		stroke-width: 1.1;
		fill: none;
		transform: scale(1.5);

		&.active {
			// fill: ${colors.primary};
			stroke: ${colors.primary};
		}
		cursor: pointer;
	}

	/* Popup container - can be anything you want */
	.popup {
		position: relative;
		display: inline-block;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	/* The actual popup */
	.popup .popuptext {
		visibility: hidden;
		width: 300px;
		border-radius: 5px;
		background: #fff;
		border: 1px solid #eee;
		padding: 15px 20px;
		position: absolute;
		z-index: 1;
		top: 25px;
		right: 0;
		box-shadow: -10px 0px 20px 0px rgb(0 0 0 / 10%);
	}

	/* Toggle this class - hide and show the popup */
	.popup .show {
		visibility: visible;
	}
`;

const Component = ({ ...props }: TProps) => {
	const { messages } = useSelector((store: TGlobalState) => store.Locale);
	const [filters, setFilters] = useState<any>({});

	useEffect(() => {
		props.onFilter && props.onFilter(filters);
	}, [filters]);

	const onFilter = (value: any, key: string) => {
		const _ = { ...filters };

		if (value === undefined) {
			delete _[key];
		} else {
			_[key] = value;
		}

		setFilters(_);

		const popup = document.getElementById('filters-popup');
		popup?.classList.remove('show');
	};

	const clearFilters = () => {
		setFilters({});
		const popup = document.getElementById('filters-popup');
		popup?.classList.remove('show');
	};

	const areFiltersSet = Object.keys(filters).length > 0;

	return (
		<Styled>
			<div className="popup">
				<svg
					height="16"
					width="16"
					className={`filter-icon ${areFiltersSet ? 'active' : ''}`}
					onClick={() => {
						const popup = document.getElementById('filters-popup');
						popup?.classList.toggle('show');
					}}>
					<polygon points="2,2 14,2 14,3 9,9 9,12 7,15 7,9 2,3" />
				</svg>

				<div className="popuptext" id="filters-popup">
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}>
						<Typography.Title level={3} style={{ margin: 0 }}>
							{messages['filters']}
						</Typography.Title>

						{areFiltersSet && (
							<Button type="link" color="default" onClick={clearFilters}>
								{messages['reset']}
							</Button>
						)}
					</div>

					{props.filters.map(({ key, label, type, options }, index) => {
						switch (type) {
							case 'select': {
								return (
									<Select
										value={filters[key]}
										label={label}
										options={options}
										onChange={value => {
											onFilter(value, key);
										}}
									/>
								);
							}
							case 'checkbox': {
								return (
									<Checkbox
										label={label}
										onChange={value => {
											onFilter(value, key);
										}}
									/>
								);
							}
						}
					})}
				</div>
			</div>
		</Styled>
	);
};

export default Component;
