import { all, fork, put } from 'redux-saga/effects';
import { ACTIONS } from './types';
import { getBrowserLang } from './reducer';
import { store } from '../store';
import { messages } from '@golee/gle-polylang';

const loadLanguages = async () => {
	const language = 'it'; // getBrowserLang();

	store.dispatch({
		type: ACTIONS.LOAD_LANGUAGE,
		payload: {
			language,
			messages: {
				// @ts-ignore
				...(await messages(language)),
			},
		},
	});
};

export default function* rootSaga() {
	yield all([fork(loadLanguages)]);
}
