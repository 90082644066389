import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { $loadNotifications } from '../../redux/Notifications/middleware';
import { TGlobalState } from '../../redux/types';
import Item from './Item';

const Wrapper = styled.div`
	height: 100%;
	overflow: auto;

	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
`;

type TProps = {
	style?: React.CSSProperties;
	className?: string;
};

const List = ({ style = {}, ...props }: TProps) => {
	const dispatch = useDispatch();

	const { profile } = useSelector((state: TGlobalState) => state.Auth);

	const { data } = useSelector((state: TGlobalState) => state.Notifications);

	const [offset, setOffset] = useState<number>(0);

	const loadMoreData = async (): Promise<any> => {
		try {
			dispatch($loadNotifications(profile.sub, offset, 20));
		} catch (err) {
			// TODO:
			console.error(err);
		}
	};

	useEffect(() => {
		setOffset(data.length);
	}, [data]);

	return (
		<Wrapper style={style} className={props.className}>
			{data.map(notification => (
				<Item notification={notification} className={props.className}/>
			))}
		</Wrapper>
		// <StyledComponent>
		// 	<div
		// 		className={`gleNotification-list ${props.theme} ${
		// 			props.dashboard ? 'dashboard' : ''
		// 		} ${isMobile() ? 'mobile' : ''}`}>
		// 		{props.showHeader && (
		// 			<div className="gleNotification-list-header">
		// 				<Typography.Text level={1} color={'gray'}>
		// 					{props.locale.messages['notices_and_notifications']}
		// 				</Typography.Text>

		// 				<ReadAll />
		// 			</div>
		// 		)}

		// 		{!!props.notifications.data.length && (
		// 			<List
		// 				dataSource={props.notifications.data}
		// 				renderItem={(item: any, index: number) => (
		// 					// @ts-ignore
		// 					<Item item={item} theme={props.theme} />
		// 				)}
		// 			/>
		// 		)}

		// 		{props.notifications.loading && (
		// 			<div
		// 				style={{
		// 					display: 'flex',
		// 					alignItems: 'center',
		// 					justifyContent: 'center',
		// 					padding: '10px 0',
		// 				}}>
		// 				<Loader size={'l'} />
		// 			</div>
		// 		)}

		// 		{props.notifications.canLoadMore && (
		// 			<div className="gleNotifications-list-load-more">
		// 				<Button typographyLevel={1} type={'secondary'} onClick={loadMoreData}>
		// 					{props.locale.messages['load_others']}
		// 				</Button>
		// 			</div>
		// 		)}

		// 		{!props.notifications.loading && !props.notifications.data.length && (
		// 			<div className="gleNotifications-list-empty">
		// 				<Empty
		// 					description={props.locale.messages['not_notices_received']}
		// 					image={Empty.PRESENTED_IMAGE_SIMPLE}
		// 				/>
		// 			</div>
		// 		)}
		// 	</div>
		// </StyledComponent>
	);
};

export default List;
