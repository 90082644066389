import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Document } from '../types';
import service from '../services/service';
import { TGlobalState } from '../../../redux/types';
import Typography from '../../../components/Typography';
import Button from '../../../components/Button';

type TProps = {
	refEntity: string;
	typeEntity: string;
	label?: string;
	emptyLabel?: string;
};

const Table = ({ ...props }: TProps) => {
	const { messages } = useSelector((state: TGlobalState) => state.Locale);

	// ------------------------------------------------
	// s t a t e
	// ------------------------------------------------
	const [isLoading, setIsLoading] = useState<boolean>();

	const [documents, setDocuments] = useState<Document[]>([]);

	// ------------------------------------------------
	// e v e n t s
	// ------------------------------------------------
	const onLoad = async () => {
		setIsLoading(true);
		try {
			const data = await loadDocuments();
			setDocuments(data);
		} catch (err) {
			console.error(err);
		} finally {
			setIsLoading(false);
		}
	};

	// ------------------------------------------------
	// u t i l s
	// ------------------------------------------------

	// ------------------------------------------------
	// d a t a
	// ------------------------------------------------
	const loadDocuments = async (): Promise<Document[]> => {
		return service.list(props.refEntity, props.typeEntity);
	};

	// ------------------------------------------------
	// h o o k s
	// ------------------------------------------------
	useEffect(() => {
		onLoad();
	}, []);

	// ------------------------------------------------
	// r e n d e r
	// ------------------------------------------------
	return (
		<>
			{!isLoading ? (
				<>
					{documents.length ? (
						<>
							{documents.map((document, index) => {
								return (
									<>
										<a
											href={document.url}
											target="_blank"
											style={{ textDecoration: 'none' }}>
											<Button type="link" color="primary">
												{document.name}
											</Button>
										</a>
										{index < documents.length - 1 ? <>,&nbsp;</> : null}
									</>
								);
							})}
						</>
					) : (
						<Typography.Text>
							{props.emptyLabel || messages['no_documents_uploaded']}
						</Typography.Text>
					)}
				</>
			) : null}
		</>
	);
};

export default Table;
