import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TGlobalState } from '../../../redux/types';
import { TObjRichiestaAmichevole, ERichiestaAmichevoleStatus } from '@golee/gle-types';
import Typography from '../../../components/Typography';
import Button from '../../../components/Button';
import Activities from '../../../components/Activities';
import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import Semaphore from '../../../components/Semaphore';
import ForwardRequest from './components/ForwardRequest';
import RejectRequest from './components/RejectRequest';
import Clubs from './../../../components/ClubsHeader';
import { MatchPlace } from '../../../components/MatchPlace';

type TProps = {
	item: TObjRichiestaAmichevole;
	onApprove: (item: TObjRichiestaAmichevole) => void;
	onReject: (item: TObjRichiestaAmichevole, reason: string) => void;
	onForward: (item: TObjRichiestaAmichevole, delegation: string) => void;
};

const Wrapper = styled.div`
	.value {
		display: flex;
		align-items: center;
		margin-bottom: 5px;
		p {
			margin: 0;
			color: ${colors['dark_grey']};

			&:first-child {
				width: 150px;
			}
		}
	}
`;

const View = ({ item, ...props }: TProps) => {
	const messages = useSelector((store: TGlobalState) => store.Locale.messages);

	const [forceEdit, setForceEdit] = useState<boolean>(false);
	const [isRejecting, setIsRejecting] = useState<boolean>(false);
	const [isForwarding, setIsForwarding] = useState<boolean>(false);

	const renderStatus = (status: string) => {
		switch (status) {
			case ERichiestaAmichevoleStatus.APPROVED: {
				return (
					<>
						<Typography.Text style={{ marginRight: 5 }}>
							{messages['approved']}
						</Typography.Text>
						<Semaphore className={'success'} />{' '}
					</>
				);
			}
			case ERichiestaAmichevoleStatus.FORWARDED: {
				return (
					<>
						<Typography.Text style={{ marginRight: 5 }}>
							{messages['forwarded']}
						</Typography.Text>
						<Semaphore className={'info'} />{' '}
					</>
				);
			}
			case ERichiestaAmichevoleStatus.REJECTED: {
				return (
					<>
						<Typography.Text style={{ marginRight: 5 }}>
							{messages['rejected']}
						</Typography.Text>
						<Semaphore className={'danger'} />{' '}
					</>
				);
			}
			case ERichiestaAmichevoleStatus.WAITING: {
				return (
					<>
						<Typography.Text style={{ marginRight: 5 }}>
							{messages['waiting']}
						</Typography.Text>
						<Semaphore className={'warning'} />
					</>
				);
			}
		}
	};

	const canEdit = (): boolean => {
		return item.status === ERichiestaAmichevoleStatus.WAITING;
	};

	return (
		<Wrapper>
			<Typography.Title level={2}>{messages['match_details']}</Typography.Title>

			<Clubs
				home={{
					logo: item.home_team.club_logo,
					club_id: item.home_team.club,
					club_name: item.home_team.club_name,
					team_name: item.home_team.team_name,
					is_applicant: item.home_team.is_applicant,
				}}
				away={{
					logo: item.away_team.club_logo,
					club_id: item.away_team.club,
					club_name: item.away_team.club_name,
					team_name: item.away_team.team_name,
					is_applicant: item.away_team.is_applicant,
				}}
			/>

			<div className="value">
				<Typography.Text>{messages['date']}:</Typography.Text>
				<Typography.Text>{item.match_date}</Typography.Text>
			</div>

			<div className="value">
				<Typography.Text>{messages['time']}:</Typography.Text>
				<Typography.Text>{item.match_time}</Typography.Text>
			</div>

			<div className="value">
				<Typography.Text>{messages['place']}:</Typography.Text>
				<Typography.Text>
					<MatchPlace item={item} />
				</Typography.Text>
			</div>

			<div style={{ marginTop: 20, paddingTop: 20, borderTop: '1px solid #eee' }}>
				<div className="value" style={{ marginBottom: 10 }}>
					<Typography.Text>{messages['module']}:</Typography.Text>

					{item.document_url ? (
						<Typography.Text
							onClick={() => {
								// setIsViewingPdf(true);
								window.open(item.document_url, '_blank');
							}}
							style={{ color: colors['primary'], cursor: 'pointer' }}>
							{messages['view_module']}
						</Typography.Text>
					) : (
						<Typography.Text>{messages['not_available']}</Typography.Text>
					)}
				</div>

				<div className="value">
					<Typography.Text>{messages['status']}:</Typography.Text>
					{renderStatus(item.status)}
				</div>
			</div>

			{(canEdit() || forceEdit) && !isRejecting && !isForwarding && (
				<div style={{ marginTop: 40 }}>
					<div style={{ display: 'flex', justifyContent: 'space-around' }}>
						{item.status !== ERichiestaAmichevoleStatus.APPROVED && (
							<Button
								type="outline"
								color="success"
								id="btn-approve"
								onClick={() => props.onApprove(item)}>
								{messages['approve']}
							</Button>
						)}

						{item.status !== ERichiestaAmichevoleStatus.FORWARDED && (
							<Button
								type="outline"
								color="primary"
								disabled={isRejecting || isForwarding}
								onClick={() => setIsForwarding(true)}>
								{messages['forward']}
							</Button>
						)}

						{item.status !== ERichiestaAmichevoleStatus.REJECTED && (
							<Button
								color="danger"
								type="outline"
								onClick={() => setIsRejecting(true)}
								disabled={isRejecting || isForwarding}>
								{messages['reject']}
							</Button>
						)}
					</div>
				</div>
			)}

			{!forceEdit && !canEdit() && (
				<div
					style={{
						marginTop: 40,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<Button color="default" onClick={() => setForceEdit(true)}>
						{messages['edit']}
					</Button>
				</div>
			)}

			{isRejecting && (
				<div style={{ marginTop: 30 }}>
					<RejectRequest
						onCancel={() => setIsRejecting(false)}
						onConfirm={reason => props.onReject(item, reason)}
					/>
				</div>
			)}
			{isForwarding && (
				<div style={{ marginTop: 30 }}>
					<ForwardRequest
						onCancel={() => setIsForwarding(false)}
						onConfirm={delegation => props.onForward(item, delegation)}
					/>
				</div>
			)}

			{/* {isViewingPdf && (
				<PdfViewer
					url={item.document_url}
					title={messages['module']}
					onClose={() => setIsViewingPdf(false)}
				/>
			)} */}

			<div style={{ marginTop: 30, borderTop: '1px solid #eee', paddingTop: 30 }}>
				<Activities.List history={item.history} />
			</div>
		</Wrapper>
	);
};

export default View;
