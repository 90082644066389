export const colors = {
	primary: '#1064AC',
	primary_dark: '#0e5a9a',
	primary_dark_1: '#0c5089',
	primary_light: '#1064AC',
	primary_light_1: '#2773b4',
	primary_light_4: '#a8c7e1',

	figc_green: '#319D3A',
	figc_gold: '#C9B780',
	figc_red: '#E4161A',

	grey: '#dddddd',
	dark_grey: '#333333',
	dark_grey_1: '#a3a3a3',
	dark_grey_2: '#777777',
	light_grey: '#eeeeee',
	light_grey_1: '#f5f5f5',
	light_grey_2: '#f7f7f7',

	white: '#ffffff',

	success: '#2a9d8f',
	success_dark: '#258d80',
	success_dark_1: '#217d72',
	success_light: '#7fc4bb',

	danger: '#e76f51',
	danger_dark: '#cf6348',
	danger_dark_1: '#b85840',
	danger_light: '#f0a896',

	warning: '#e9c46a',
	warning_dark: '#d1b05f',
	warning_dark_1: '#ba9c54',
	warning_light: '#f1dba5',

	info: '#8ecae6',
	info_dark: '#7fb5cf',
	info_dark_1: '#71a1b8',
	info_light: '#c6e4f2',

	default: '#848484',
	default_dark: '#767676',
	default_dark_1: '#696969',
	default_light: '#b5b5b5',

	golee_red: '#FA6761'
};
