import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 80px;
	width: 100%;
	padding: 0 30px;
`;

const Header = ({
	children,
	style = {},
}: {
	children: React.ReactNode;
	style?: React.CSSProperties;
}) => {
	return <Wrapper style={style}>{children}</Wrapper>;
};

export default Header;
