import styled from 'styled-components';

const Wrap = styled.div`
	&.skeleton-row {
		background-color: rgba(0, 0, 0, 0.05);
		transform: translate3d(0px, 0px, 0px);
		position: relative;
		border-radius: 5px;
		height: 15px;
		overflow: hidden;
		width: 100%;
	}

	&.skeleton-row:nth-child(2) {
		opacity: 0.6;
	}

	&.skeleton-row:nth-child(3) {
		opacity: 0.4;
	}

	@keyframes bg-move {
		0% {
			transform: translateX(-100%);
		}
		100% {
			transform: translateX(100%);
		}
	}

	&.skeleton-row > div {
		transform: translate3d(0px, 0px, 0px);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(
			to right,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.075) 30%,
			rgba(0, 0, 0, 0.075) 40%,
			rgba(0, 0, 0, 0) 80%
		);
		animation: bg-move 1.5s -0.75s ease infinite;
	}
`;

const Skeleton = ({ style = {} }: { style?: React.CSSProperties }) => {
	return (
		<Wrap className="skeleton-row" style={style}>
			<div></div>
		</Wrap>
	);
};

export default Skeleton;
