import { TState, ACTIONS, TActions } from './types';

const initialState: TState = {
	regions: {},
	provinces: {},
	countries: {},
};

export default function reducer(state = initialState, action: TActions): TState {
	switch (action.type) {
		case ACTIONS.LOAD: {
			return {
				...state,
				...action.payload,
			};
		}

		default:
			return state;
	}
}
