import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../utils/colors';
import Typography from '../../../Typography';

type TProps = {
	label: string;
	onChange: (ev: any) => void;
};

const Styled = styled.div`
	/* Customize the label (the container) */
	.container {
		display: block;
		position: relative;
		padding-left: 30px;
		margin-bottom: 12px;
		cursor: pointer;
		font-size: 18px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	/* Hide the browser's default checkbox */
	.container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		border-radius: 5px;
		background-color: #eee;
	}

	/* On mouse-over, add a grey background color */
	.container:hover input ~ .checkmark {
		background-color: #ccc;
	}

	/* When the checkbox is checked, add a blue background */
	.container input:checked ~ .checkmark {
		background-color: ${colors.primary};
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	.container input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	.container .checkmark:after {
		left: 6px;
		top: 2px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
`;

const Checkbox = ({ ...props }: TProps) => {
	const [check, setCheck] = useState<boolean>(false);

	useEffect(() => {
		props.onChange(check);
	}, [check]);

	return (
		<Styled
			onClick={e => {
				e.stopPropagation();
				e.preventDefault();
				setCheck(!check);
			}}>
			<label className="container">
				<Typography.Text>{props.label}</Typography.Text>
				<input type="checkbox" checked={check} />
				<span className="checkmark"></span>
			</label>
		</Styled>
	);
};

export default Checkbox;
