import { useDispatch, useSelector } from 'react-redux';
import Typography from '../../../../components/Typography';
import { TGlobalState } from '../../../../redux/types';
import { TObjChampionship } from '@golee/gle-types';
import Table from '../../../../components/@table/Table/Table';
import Layout from '../../../../layout';
import { ReportCounter } from '../components/RefertiCounter';
import React from 'react';
import { MissingReportCounter } from '../components/MissingRefertiCounter';

type TProps = {
	data: any[];
	loading: boolean;
	onRowClick?: (item: TObjChampionship) => void;
	onLoadMore?: () => void;
	canLoadMore?: boolean;
	onFilter?: (filters: { [key: string]: any }) => void;
};

const View = ({ ...props }: TProps) => {
	const { messages, language } = useSelector((store: TGlobalState) => store.Locale);
	const { regions, countries } = useSelector((store: TGlobalState) => store.Locations);

	const columns = React.useMemo(
		() => [
			{
				title: messages['name'],
				id: 'name',
				accessor: (row: TObjChampionship) => {
					return row.name;
				},
				size: 3,
			},
			// {
			// 	title: messages['region'],
			// 	id: 'region',
			// 	accessor: (row: TObjChampionship) => {
			// 		if (row.region) {
			// 			return regions[row.region][language];
			// 		}
			// 		return '';
			// 	},
			// 	size: 2,
			// },
			// {
			// 	title: messages['nation'],
			// 	id: 'nation',
			// 	accessor: (row: TObjChampionship) => {
			// 		if (row.nation) {
			// 			return countries[row.nation][language];
			// 		}
			// 		return '';
			// 	},
			// 	size: 2,
			// },
			{
				title: messages['rounds'],
				id: 'gironi',
				accessor: (row: TObjChampionship) => {
					return row.gironi.length || 0;
				},
			},
			{
				title: messages['participants'],
				id: 'partecipanti',
				accessor: (row: TObjChampionship) => {
					return row.partecipanti.length || 0;
				},
			},
			{
				title: messages['match_reports'],
				id: 'counter',
				accessor: (row: TObjChampionship) => {
					return <ReportCounter ref_championship={row._id} />;
				},
			},
			{
				title: messages['missing_reports'],
				id: 'missing_referti',
				accessor: (row: TObjChampionship) => {
					return <MissingReportCounter ref_championship={row._id} />;
				},
			},
		],
		[]
	);

	const data = React.useMemo(() => props.data, [props.data]);

	return (
		<>
			<Layout.Header>
				<Typography.Title>{messages['select_championship']}</Typography.Title>
			</Layout.Header>

			<Layout.Content>
				<Table
					columns={columns}
					data={data}
					onRowClick={props.onRowClick}
					onLoadMore={props.onLoadMore}
					canLoadMore={props.canLoadMore}
					loading={props.loading}
					noDataMessage={'Nessun dato trovato'}
				/>
			</Layout.Content>
		</>
	);
};

export default View;
