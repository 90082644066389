import { EDelegations, ERegions, TObjDelegation } from '@golee/gle-types';

export type TStateDelegations = {
	data: TObjDelegation[];
	loading: boolean;
	current?: TObjDelegation;
	currentValue?: EDelegations;
	province?: EDelegations;
	region?: ERegions;
};

export enum ACTIONS {
	SET_LOADING = 'SET_LOADING_DELEGATIONS',
	SET = 'SET_DELEGATIONS',
	SET_CURRENT = 'SET_CURRENT_DELEGATIONS',
}

export type TActLoading = {
	type: ACTIONS.SET_LOADING;
	payload: boolean;
};

export type TActSet = {
	type: ACTIONS.SET;
	payload: TObjDelegation[];
};

export type TActSetCurrent = {
	type: ACTIONS.SET_CURRENT;
	payload: TObjDelegation;
};

export type TActions = TActLoading | TActSet | TActSetCurrent;
