import { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import Typography from '../../../../components/Typography';
import { TGlobalState } from '../../../../redux/types';

type TProps = {
	onCancel: () => void;
	onConfirm: (reason: string) => void;
};

const RejectRequest = ({ ...props }: TProps) => {
	const messages = useSelector((store: TGlobalState) => store.Locale.messages);
	const [reason, setReason] = useState<string>('');

	return (
		<>
			<Typography.Title level={4}>Inserisci motivazione</Typography.Title>
			<textarea onChange={ev => setReason(ev.target.value)}></textarea>

			<div
				style={{
					marginTop: 20,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					gap: 15
				}}>
				<Button color="default" type="link" onClick={() => props.onCancel()}>
					{messages['cancel']}
				</Button>
				<Button color="danger" disabled={!reason} onClick={() => props.onConfirm(reason)}>
					{messages['reject']}
				</Button>
			</div>
		</>
	);
};

export default RejectRequest;
