import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { colors } from '../../utils/colors';
import Typography from '../Typography';

const Wrap = styled.span`
	display: flex;
	align-items: center;
	gap: 10px;
	img {
		height: 25px;
	}

	.applier {
		border-radius: 50%;
		position: relative;
		left: -3px;
		width: 6px;
		height: 6px;
		display: inline-block;
		background-color: ${colors.dark_grey_1};
	}
`;

type Team = {
	team: number;
	team_name: string;
	club: number;
	club_name: string;
	is_applicant?: boolean;
	club_logo?: string;
};

const Cell = ({ team, logoOnRight }: { team: Team; logoOnRight?: boolean }) => {
	return (
		<>
			<Wrap>
				{/* {!logoOnRight && team.club_logo && <img src={team.club_logo} />} */}
				<div>
					<Typography.Text level={1} style={{ margin: 0 }}>
						{team.club_name}
					</Typography.Text>

					<Typography.Text level={3} style={{ margin: 0, color: colors['dark_grey_2'] }}>
						{team.team_name}
					</Typography.Text>
				</div>
				{/* {team.is_applicant && (
					<span
						data-tip={'Ha inviato la richiesta'}
						data-effect="solid"
						className="applier"></span>
				)} */}
				{/* {logoOnRight && team.club_logo && <img src={team.club_logo} />} */}
			</Wrap>
			{/* <ReactTooltip /> */}
		</>
	);
};

export default Cell;
