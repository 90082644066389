import { useSelector } from 'react-redux';
import { TGlobalState } from '../../../../../redux/types';
import { TObjChampionship, TObjRefertoGara, ERefertoGaraStatus } from '@golee/gle-types';
import Table from '../../../../../components/@table/Table/Table';
import Layout from '../../../../../layout';
import Semaphore from '../../../../../components/Semaphore';
import { TeamTableCell } from '../../../../../components/TeamTableCell';
import React from 'react';
import { TColumn } from '../../../../../components/@table/Table';
import { Filters } from '../../../../../components/@table/Filters';
import { Search } from '../../../../../components/@table/Search';
import { ListViewProps } from '../../../../types';
import { Paginator } from '../../../../../components/@table/Pagination';

const View = ({ ...props }: ListViewProps & { championship: TObjChampionship }) => {
	const messages = useSelector((store: TGlobalState) => store.Locale.messages);

	const _columns: TColumn<TObjRefertoGara>[] = [
		{
			title: messages['home'],
			id: 'home',
			accessor: row => {
				return <TeamTableCell team={row.home_team} />;
			},
			size: 2,
		},
		{
			title: messages['away'],
			id: 'away',
			accessor: row => {
				return <TeamTableCell team={row.away_team} />;
			},
			size: 2,
		},
		{
			title: messages['round'],
			id: 'girone',
			accessor: row => {
				const girone = props.championship.gironi.find(t => t._id === row.ref_girone);
				return girone?.name || '-';
			},
		},
		{
			title: messages['day'],
			id: 'step',
			sortable: true,
			accessor: row => {
				return row.step || '-';
			},
		},
		{
			title: messages['date'],
			sortable: true,
			id: 'match_date',
			accessor: 'match_date',
		},
		{
			title: messages['time'],
			sortable: true,
			id: 'match_time',
			accessor: 'match_time',
		},
		{
			title: messages['status'],
			id: 'status',
			sortable: true,
			accessor: row => {
				switch (row.status) {
					case ERefertoGaraStatus.APPROVED: {
						return (
							<Semaphore
								tooltip={messages[row.status.toLowerCase()]}
								className={'success'}
							/>
						);
					}
					case ERefertoGaraStatus.FORWARDED: {
						return (
							<Semaphore
								tooltip={messages[row.status.toLowerCase()]}
								className={'info'}
							/>
						);
					}
					case ERefertoGaraStatus.REJECTED: {
						return (
							<Semaphore
								tooltip={messages[row.status.toLowerCase()]}
								className={'danger'}
							/>
						);
					}
					case ERefertoGaraStatus.WAITING: {
						return (
							<Semaphore
								tooltip={messages[row.status.toLowerCase()]}
								className={'warning'}
							/>
						);
					}
				}
			},
		},
	];

	const columns = React.useMemo(() => _columns, []);

	const data = React.useMemo(() => props.data, [props.data]);

	return (
		<>
			<Layout.Content style={{ height: 'auto' }}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
						height: 30,
						gap: 20,
					}}>
					<Search
						placeholder={'Cerca...'}
						onChange={ev => props.onSearch && props.onSearch(ev)}
					/>

					<Filters
						filters={[
							{
								label: messages['status'],
								key: 'status',
								type: 'select',
								options: [
									ERefertoGaraStatus.WAITING,
									ERefertoGaraStatus.APPROVED,
									ERefertoGaraStatus.FORWARDED,
									ERefertoGaraStatus.REJECTED,
								].map(t => ({ label: messages[t.toLowerCase()], value: t })),
							},
							{
								label: 'Filtra per girone',
								key: 'ref_girone',
								type: 'select',
								options: props.championship.gironi.map(t => ({
									label: t.name,
									value: t._id,
								})),
							},
						]}
						onFilter={props.onFilter}
					/>
				</div>

				<Table
					columns={columns}
					data={data}
					onRowClick={props.onRowClick}
					loading={props.loading}
					noDataMessage={'Nessun dato trovato'}
					onSort={e => props.onSort && props.onSort(e?.stringified)}
				/>

				{props.pagination && <Paginator {...props.pagination} />}
			</Layout.Content>
		</>
	);
};

export default View;
