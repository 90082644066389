import styled from 'styled-components';
import Loader from './Loader';

const Wrap = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
`;

const FullLoader = () => {
	return (
		<Wrap>
			<Loader />
		</Wrap>
	);
};

export default FullLoader;
