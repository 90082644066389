import { TObjRefertoGara } from '@golee/gle-types';

export type TStateMatchReports = {
	data: TObjRefertoGara[];
	loading: boolean;
	offset: number;
	limit: number;
	canLoadMore: boolean;
	current?: TObjRefertoGara;
	filters: any;
	sort: any;
};

export enum ACTIONS {
	SET_LOADING = 'SET_LOADING_MATCH_REPORTS',
	LOAD = 'LOAD_MATCH_REPORTS',
	LOAD_MORE = 'LOAD_MORE_MATCH_REPORTS',
	SET_CURRENT = 'SET_CURRENT_MATCH_REPORT',
	UPDATE = 'UPDATE_MATCH_REPORT',
	SET_FILTERS = 'SET_FILTERS_MATCH_REPORTS',
	ADD_FILTER = 'ADD_FILTER_MATCH_REPORTS',
	SET_SORT = 'SET_SORT_MATCH_REPORTS',
	SET_OFFSET = 'SET_OFFSET_MATCH_REPORTS',
	RESET = 'RESET_MATCH_REPORTS',
}

export type TActLoading = {
	type: ACTIONS.SET_LOADING;
	payload: boolean;
};

export type TActLoad = {
	type: ACTIONS.LOAD;
	payload: TObjRefertoGara[];
};

export type TActLoadMore = {
	type: ACTIONS.LOAD_MORE;
	payload: TObjRefertoGara[];
};

export type TActSetCurrent = {
	type: ACTIONS.SET_CURRENT;
	payload: TObjRefertoGara;
};

export type TActUpdate = {
	type: ACTIONS.UPDATE;
	payload: TObjRefertoGara;
};

export type TActSetFilters = {
	type: ACTIONS.SET_FILTERS;
	payload: any;
};

export type TActAddFilter = {
	type: ACTIONS.ADD_FILTER;
	payload: any;
};

export type TActSetSort = {
	type: ACTIONS.SET_SORT;
	payload: any;
};

export type TActSetOffset = {
	type: ACTIONS.SET_OFFSET;
	payload: number;
};

export type TActReset = {
	type: ACTIONS.RESET;
};

export type TActions =
	| TActLoading
	| TActLoadMore
	| TActSetCurrent
	| TActUpdate
	| TActLoad
	| TActSetFilters
	| TActAddFilter
	| TActSetSort
	| TActSetOffset
	| TActReset;
