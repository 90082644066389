import App from './App/reducer';
import Auth from './Auth/reducer';
import Locale from './Locale/reducer';
import Locations from './Locations/reducer';
import Notifications from './Notifications/reducer';
import Delegations from './Delegations/reducer';
import MatchReports from './MatchReports/reducer';
import Championships from './Championships/reducer';

const _ = {
	App,
	Auth,
	Locale,
	Locations,
	Notifications,
	Delegations,
	MatchReports,
	Championships,
};

export default _;
