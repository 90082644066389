import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { TGlobalState } from '../../redux/types';
import routes from '../../router/routes';
import { MenuChild, MenuLink, MenuParent } from './components';

const Style = styled.div``;

type TProps = {
	children?: React.ReactNode;
};

const Menu = ({ children, ...props }: TProps) => {
	const dispatch = useDispatch();
	const messages = useSelector((store: TGlobalState) => store.Locale.messages);

	const { pathname } = useSelector((store: TGlobalState) => store.router.location);

	const onNavigate = (path: string) => {
		dispatch(push(path));
	};

	const isSelected = (path: string, exact: boolean = true) => {
		if (exact) {
			return path === pathname;
		}

		return pathname.includes(path);
	};

	return (
		<Style className="menu">
			<MenuParent>
				<MenuChild className={`${isSelected(routes.DASHBOARD) ? 'selected' : ''}`}>
					<MenuLink onClick={() => onNavigate(routes.DASHBOARD)}>
						<i className="las la-tachometer-alt"></i>
						<span>{messages['dashboard']}</span>
					</MenuLink>
				</MenuChild>
				<MenuChild
					className={`${isSelected(routes.FRIENDLY_MATCH_REQUESTS) ? 'selected' : ''}`}>
					<MenuLink onClick={() => onNavigate(routes.FRIENDLY_MATCH_REQUESTS)}>
						<i className="las la-user-friends"></i>
						<span>{messages['friendly_match_requests']}</span>
					</MenuLink>
				</MenuChild>
				<MenuChild
					className={`${
						isSelected(routes.CHAMPIONSHIPS) || isSelected(routes.MATCH_REFERTI, false)
							? 'selected'
							: ''
					}`}>
					<MenuLink onClick={() => onNavigate(routes.CHAMPIONSHIPS)}>
						<i className="las la-file-contract"></i>{' '}
						<span>{messages['match_reports']}</span>
					</MenuLink>
				</MenuChild>
				<MenuChild className={`${isSelected(routes.MATCH_VARIATIONS) ? 'selected' : ''}`}>
					<MenuLink onClick={() => onNavigate(routes.MATCH_VARIATIONS)}>
						<i className="las la-random"></i>
						<span>{messages['variation_requests']}</span>
					</MenuLink>
				</MenuChild>
			</MenuParent>
		</Style>
	);
};

export default Menu;
