import { push } from 'connected-react-router';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from '../../../../components/Toast/toast';
import {
	$loadChampionships,
	$setCurrentChampionship,
} from '../../../../redux/Championships/middleware';
import { actLoad } from '../../../../redux/Championships/actions';
import { TGlobalState } from '../../../../redux/types';
import routes from '../../../../router/routes';
import View from './view';

const Container = () => {
	const delegation = useSelector((store: TGlobalState) => store.Delegations.province);
	const region = useSelector((store: TGlobalState) => store.Delegations.region);

	const { data, loading, canLoadMore } = useSelector(
		(store: TGlobalState) => store.Championships
	);
	const { messages } = useSelector((store: TGlobalState) => store.Locale);

	const dispatch = useDispatch();

	const [filters, setFilters] = useState<any>();

	const loadData = () => {
		dispatch(
			$loadChampionships(filters, undefined, true, () => {
				toast.error(messages['error']);
			})
		);
	};

	const loadMoreData = () => {
		dispatch(
			$loadChampionships(filters, undefined, false, () => {
				toast.error(messages['error']);
			})
		);
	};

	useEffect(() => {
		if (filters) {
			if (Object.keys(filters).length) {
				loadData();
			}
		}
	}, [filters]);

	useEffect(() => {
		if (delegation) {
			setFilters({ delegation });
		}
	}, [delegation]);

	useEffect(() => {
		if (region) {
			setFilters({ region });
		}
	}, [region]);

	useEffect(() => {
		return () => {
			dispatch(actLoad([]));
		};
	}, []);

	return (
		<>
			<View
				data={data}
				loading={loading}
				onRowClick={item => {
					dispatch($setCurrentChampionship(item));
					dispatch(push(`${routes.CHAMPIONSHIPS}/${item._id}${routes.MATCH_REFERTI}#received_referti`));
				}}
				onLoadMore={loadMoreData}
				canLoadMore={canLoadMore}
				onFilter={filters => {
					setFilters(filters);
				}}
			/>
		</>
	);
};

export default Container;
