import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TGlobalState } from '../../redux/types';
import {
	TObjRichiestaAmichevoleHistory,
	ERichiestaAmichevoleHistoryActionType,
} from '@golee/gle-types';
import moment from 'moment';
import Typography from '../Typography';
import styled from 'styled-components';
import { colors } from '../../utils/colors';

type TProps = {
	history: TObjRichiestaAmichevoleHistory[];
};

const Wrapper = styled.div`
	.history {
		margin-top: 10px;
		> div {
			padding: 10px 0;
			p {
				color: ${colors['dark_grey']};
				&.date {
					color: ${colors['dark_grey_2']};
					margin: 0;
					margin-top: 5px;
				}
			}

			&:first-child {
				padding-top: 0px;
			}

			&:not(:last-child) {
				border-bottom: 1px solid ${colors['light_grey']};
			}
		}
	}
`;

const List = ({ history, ...props }: TProps) => {
	const messages = useSelector((store: TGlobalState) => store.Locale.messages);

	const getText = (item: TObjRichiestaAmichevoleHistory): string => {
		switch (item.action_type) {
			case ERichiestaAmichevoleHistoryActionType.CREATED: {
				return 'ha creato la richiesta.';
			}
			case ERichiestaAmichevoleHistoryActionType.APPROVED_FIGC: {
				return 'ha approvato la richiesta.';
			}
			case ERichiestaAmichevoleHistoryActionType.FORWARDED: {
				return 'ha inoltrato la richiesta.';
			}
			case ERichiestaAmichevoleHistoryActionType.SUBSTITUTED: {
				return 'ha sostituito la richiesta.';
			}
			case ERichiestaAmichevoleHistoryActionType.REJECTED_FIGC: {
				return 'ha rifiutato la richiesta.';
			}
			case ERichiestaAmichevoleHistoryActionType.APPROVED_OPPONENT: {
				return 'ha approvato la richiesta.';
			}
			case ERichiestaAmichevoleHistoryActionType.REJECTED_OPPONENT: {
				return 'ha rifiutato la richiesta.';
			}
		}

		return '';
	};

	return (
		<Wrapper>
			<Typography.Title level={3}>{messages['activity_history']}</Typography.Title>

			<div className="history">
				{history
					.sort((a, b) => moment(a.date_time).unix() - moment(b.date_time).unix())
					.map((t, i) => (
						<div key={`activity-${i}`}>
							<Typography.Text style={{ margin: 0 }}>
								<strong>{t.who_made_label}</strong> {getText(t)}
							</Typography.Text>
							<Typography.Text className="date" level={3}>
								{moment(t.date_time, 'HH:mm DD/MM/YYYY')
									// temp fix
									.utcOffset('+0400')
									.format('HH:mm DD/MM/YYYY')}
							</Typography.Text>
						</div>
					))}
			</div>
		</Wrapper>
	);
};

export default List;
