import React, { useState, useEffect } from 'react';
import View from './view';

const Container = () => {
	useEffect(() => {}, []);

	return (
		<>
			<View />
		</>
	);
};

export default Container;
