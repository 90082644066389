import { EDelegationType, TObjDelegation } from '@golee/gle-types';
import { useSelector } from 'react-redux';
import { TGlobalState } from '../../redux/types';

type TProps = {
	onChange?: (value: string) => void;
	disabled?: boolean;
};

const Select = ({ ...props }: TProps) => {
	const { regions, provinces } = useSelector((store: TGlobalState) => store.Locations);
	const { messages, language } = useSelector((store: TGlobalState) => store.Locale);

	const { data, current } = useSelector((store: TGlobalState) => store.Delegations);

	const getLabel = (delegation: TObjDelegation): string => {
		let label = '';

		if (delegation.type === EDelegationType.PRO) {
			if (delegation.province) {
				label = `${messages['provincial_delegation']} ${
					provinces[delegation.province][language]
				}`;
			}

			if (delegation.region) {
				label += ` (${regions[delegation.region][language]})`;
			}
		} else if (delegation.type === EDelegationType.REG) {
			if (delegation.region) {
				label = `${messages['regional_delegation']} ${
					regions[delegation.region][language]
				}`;
			}
		}

		return label;
	};

	return (
		<div style={{ marginTop: 10 }}>
			<select onChange={ev => props.onChange && props.onChange(ev.target.value)}>
				<option></option>
				{data
					.filter(t => t._id !== current?._id)
					.map(t => (
						<option value={t._id}>{getLabel(t)}</option>
					))}
			</select>
		</div>
	);
};

export default Select;
