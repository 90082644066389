import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTable } from 'react-table';

import { Skeleton } from '../../Skeleton';
import Typography from '../../Typography';
import Sort from './Components/Sort';
import Styles from './styles';
import { TSortDirection, TTable } from './types';

const Component = ({
	data,
	onRowClick,
	onLoadMore,
	canLoadMore,
	loading,
	noDataMessage = 'No data',
	...props
}: TTable<any>) => {
	const [template, setTemplate] = useState<React.CSSProperties>();

	const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable({
		columns: props.columns as any,
		data,
	});

	const [sort, setSort] = useState<any>();

	// const observer = useRef<any>();
	// const lastElementRef = useCallback(
	// 	node => {
	// 		if (loading) return;
	// 		if (observer.current) observer.current.disconnect();

	// 		observer.current = new IntersectionObserver(entries => {
	// 			if (entries[0].isIntersecting && canLoadMore) {
	// 				onLoadMore && onLoadMore();
	// 			}
	// 		});

	// 		if (node) observer.current.observe(node);
	// 	},
	// 	[loading, canLoadMore]
	// );

	const calcLayout = () => {
		const gridTemplateColumns = props.columns
			.map((c: any) => (c.size ? `${c.size}fr` : `${1}fr`))
			.join(' ');

		setTemplate({ gridTemplateColumns });
	};

	useEffect(() => {
		calcLayout();
	}, [props.columns]);

	return (
		<Styles>
			<table {...getTableProps()}>
				<thead>
					<tr style={template}>
						{props.columns.map(column => (
							<>
								{column.sortable ? (
									<Sort
										title={column.title}
										id={column.id}
										current={sort}
										onSort={value => {
											setSort(value);
											props.onSort && props.onSort(value);
										}}
									/>
								) : (
									<th>
										<Typography.Text level={2}>{column.title}</Typography.Text>
									</th>
								)}
							</>
						))}
					</tr>
				</thead>

				<tbody {...getTableBodyProps()}>
					{rows.map((row, i) => {
						prepareRow(row);

						// const isLastElement = data.length === i + 1;

						return (
							<tr
								{...row.getRowProps()}
								style={template}
								// ref={isLastElement ? lastElementRef : null}
								className={`${!!onRowClick ? 'hoverable' : ''}`}
								onClick={() => onRowClick && onRowClick(row.original)}>
								{row.cells.map(cell => {
									return (
										<td {...cell.getCellProps()}>
											<div>{cell.render('Cell')}</div>
										</td>
									);
								})}
							</tr>
						);
					})}

					{!rows.length && !loading && (
						<tr className="empty-row">
							<Typography.Text>{noDataMessage}</Typography.Text>
						</tr>
					)}

					{/* {loading && (
						<>
							<tr className={`loader`}>
								<td>
									<Skeleton style={{ height: 25 }} />
								</td>
							</tr>
							<tr className={`loader`}>
								<td>
									<Skeleton style={{ height: 25 }} />
								</td>
							</tr>
							<tr className={`loader`}>
								<td>
									<Skeleton style={{ height: 25 }} />
								</td>
							</tr>
							<tr className={`loader`}>
								<td>
									<Skeleton style={{ height: 25 }} />
								</td>
							</tr>
							<tr className={`loader`}>
								<td>
									<Skeleton style={{ height: 25 }} />
								</td>
							</tr>
						</>
					)} */}
				</tbody>
			</table>
		</Styles>
	);
};

export default Component;
