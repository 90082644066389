import TabPane, { TPropsTabPane } from './TabPane';
import StyledTabs from './styled';

type TProps = {
	onChange: (key: string) => void;
	activeKey: string;
	tabs: TPropsTabPane[];
};

const Tabs = (props: TProps) => {
	return (
		<StyledTabs>
			<div className={'header'}>
				{props.tabs.map(tab => (
					<div
						className={`tab ${props.activeKey === tab.key ? 'active' : 'inactive'}`}
						onClick={() => props.onChange(tab.key)}>
						{tab.tab}
					</div>
				))}
			</div>
			<div className={'tab-pane-wrapper'}>{props.tabs.find(tab => tab.key === props.activeKey)?.children}</div>
		</StyledTabs>
	);
};

Tabs.TabPane = TabPane;

export { Tabs };
