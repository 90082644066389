import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { TGlobalState } from '../../redux/types';
import { colors } from '../../utils/colors';
import Button from '../Button';
import Typography from '../Typography';
import { HermesService } from '../../services/Hermes';

const Modal = styled.div`
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background-color: rgb(0, 0, 0, 0.5);
	z-index: 1;
	display: ${(props: any) => (props.show ? 'block' : 'none')};
	.modal {
		background-color: white;
		border: 1px solid ${colors['grey']};
		border-radius: 10px;
		padding: 20px 20px 20px 20px;
		position: absolute;
		left: 0;
		right: 0;
		top: 20%;
		width: fit-content;
		margin-left: auto;
		margin-right: auto;
		box-shadow: 10px 10px 20px 10px rgb(0 0 0 / 15%);
		.title {
			margin-bottom: 10px;
		}
		.input {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 350px;
			gap: 10px;
			input {
			}
		}
	}
`;

type TProps = {
	show: boolean;
	onClose: () => void;
};

const InvitationModal = ({ show, onClose }: TProps) => {
	const [email, setEmail] = useState<string>();
	const messages = useSelector((store: TGlobalState) => store.Locale.messages);
	const federation_email = useSelector((store: TGlobalState) => store.Auth.profile.name);

	useEffect(() => {
		if (!show) setEmail('');
	}, [show]);

	const closeModal = (e: MouseEvent) => {
		e.stopPropagation();
		onClose();
	};

	return (
		//@ts-ignore
		<Modal show={show} onClick={closeModal}>
			<div className="modal" onClick={e => e.stopPropagation()}>
				<div className="title">
					<Typography.Title level={3}>{messages['invite_to_golee']}</Typography.Title>
				</div>
				<Typography.Text level={1}>{messages['email_club_manager']}</Typography.Text>
				<div className={'input'}>
					<input
						key="email"
						type="email"
						value={email}
						onChange={ev => setEmail(ev.target.value)}></input>
					<Button
						disabled={!email}
						color="primary"
						onClick={e => {
							closeModal(e);
							HermesService.sendEmailWithTemplate({
								to: [email!],
								from: federation_email,
								templateId: 'd-ae8a761fad05497ab6859ab316e1c435',
							});
						}}>
						{messages['invite']}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export { InvitationModal };
