import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import routes from '../../../../../router/routes';
import { MissingReportsService } from '../../../../../services/Matches';
import Counter from '../Counter';

type TProps = {
	ref_championship: string;
	ref_girone?: string;
};

const MissinRefertiCounter = ({ ref_championship, ref_girone, ...props }: TProps) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [count, setCount] = useState<number>(0);
	const dispatch = useDispatch()

	const loadCounter = async (): Promise<void> => {
		setIsLoading(true);
		try {
			const data = await MissingReportsService.count(ref_championship);
			setCount(data);
		} catch (err) {
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (ref_championship) {
			loadCounter();
		}
	}, [ref_championship]);

	const onClick = () => {
		dispatch(push(`${routes.CHAMPIONSHIPS}/${ref_championship}${routes.MATCH_REFERTI}#missing_referti`));
	};

	return <Counter isLoading={isLoading} count={count} onClick={onClick} />;
};

export default MissinRefertiCounter;
