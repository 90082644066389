import styled from 'styled-components';
import { colors } from '../../utils/colors';

type TProps = {
	children: React.ReactNode;
	level?: 1 | 2 | 3;
	style?: React.CSSProperties;
	className?: string;
	onClick?: (ev: any) => void;
};

const Paragraph = styled.p`
	&.link {
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}

	&.primary {
		color: ${colors['primary']};
	}
	&.success {
		color: ${colors['success']};
	}
	&.danger {
		color: ${colors['danger']};
	}
	&.gold {
		color: ${colors['figc_gold']};
	}
`;

const Text = ({ level = 1, style = {}, ...props }: TProps) => {
	switch (level) {
		case 1: {
			return (
				<Paragraph
					onClick={props.onClick}
					style={{ fontSize: 15, ...style }}
					className={props.className}>
					{props.children}
				</Paragraph>
			);
		}
		case 2: {
			return (
				<Paragraph
					onClick={props.onClick}
					style={{ fontSize: 13, ...style }}
					className={props.className}>
					{props.children}
				</Paragraph>
			);
		}
		case 3: {
			return (
				<Paragraph
					onClick={props.onClick}
					style={{ fontSize: 11, ...style }}
					className={props.className}>
					{props.children}
				</Paragraph>
			);
		}
	}
};

export default Text;
