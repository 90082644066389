import axios from './../interceptor';
import api from './api';
import { TObjRichiestaAmichevole } from '@golee/gle-types';
import { ApiQueryParams } from '../..';

export default {
	list: async ({ filters, ...params }: ApiQueryParams): Promise<TObjRichiestaAmichevole[]> => {
		try {
			const res = await axios.get(api.list(), {
				params: { ...params, ...filters },
			});
			return res.data;
		} catch (err) {
			throw err;
		}
	},

	approve: async (id: string): Promise<void> => {
		try {
			await axios.post(api.approve(id));
			return;
		} catch (err) {
			throw err;
		}
	},

	reject: async (id: string, reason: string): Promise<void> => {
		try {
			await axios.post(api.reject(id), { reject_motivation: reason });
			return;
		} catch (err) {
			throw err;
		}
	},

	forward: async (id: string, delegation: string): Promise<void> => {
		try {
			await axios.post(api.forward(id), {
				target_delegation: delegation,
			});
			return;
		} catch (err) {
			throw err;
		}
	},

	getOne: async (id: string): Promise<TObjRichiestaAmichevole> => {
		try {
			const response = await axios.get(api.single(id));
			return response.data;
		} catch (err) {
			throw err;
		}
	},
};
