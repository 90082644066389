export type TPathUrl = {
	path: string; // ex /clubs/1/teams/2903/athletes/5f8467d46a0e69513afc6812/edit
	anonymized_path: string; // ex /clubs/*/teams/*/athletes/*/edit
	scope?: Scopes;
	area?: string;
};

export class PathUrl {
	path: string;
	anonymized_path: string;
	scope?: Scopes;
	area?: string;

	constructor(path: string) {
		this.path = path;
		this.anonymized_path = path
			.replace(/#.*/, '')
			.split('/')
			.map(_ => {
				if (isNumber(_) || isObjectID(_)) _ = '*';
				return _;
			})
			.join('/');
		this.scope = getScope(this.anonymized_path);
		this.scope && (this.area = getArea(this.scope));
	}

	public getItemID(): string | undefined {
		console.log('path', this.path);
		console.log(
			'return',
			this.path
				.split('/')
				.reverse()
				.find(_ => {
					return isObjectID(_);
				})
		);
		return this.path
			.split('/')
			.reverse()
			.find(_ => {
				return isObjectID(_);
			});
	}

	public static factory(): PathUrl {
		return new PathUrl(window.location.pathname);
	}
}

const isNumber = (str: string) => new RegExp(/^\d+$/).test(str);
const isObjectID = (str: string) => new RegExp(/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i).test(str);

const getScope = (anonymized_path: string): Scopes | undefined => {
	const scopes: Scopes[] = Object.keys(Areas).reduce((acc, el) => {
		return acc.concat(Areas[el]);
	}, []);
	return <Scopes>(<any>anonymized_path
		.split('/')
		.reverse()
		.find((unit_path: any) => scopes.includes(unit_path)));
};

const getArea = (scope: Scopes): any => {
	const areas_keys: string[] = Object.keys(Areas);
	return areas_keys.find((area: string) =>
		Areas[area].find((area_scope: Scopes) => area_scope === scope)
	);
};


export enum Scopes {
	richieste_amichevole = 'friendly-match-requests',
	referti_gara = 'match-referti',
	richieste_variazioni = 'match-variations',
}

export const Areas: any = {
	richieste_amichevole: [Scopes.richieste_amichevole],
	referti_gara: [Scopes.referti_gara],
	richieste_variazioni: [Scopes.richieste_variazioni],
};
