import styled from 'styled-components';
import { colors } from '../../utils/colors';
import ModalRouteWrapper from './ModalRouteWrapper';

export const ModalRoute = styled.div`
	height: auto;
	min-height: 500px;
	border-radius: 10px;
	width: 650px;
	background-color: #fff;
	background-color: white;
	padding: 30px;
	box-shadow: -10px 0px 20px 0px rgb(0 0 0 / 10%);
	position: relative;
	margin: 30px auto;

	.close {
		i {
			color: ${colors['dark_grey_1']};
			font-size: 20px;
		}
		position: absolute;
		top: 30px;
		right: 30px;
		cursor: pointer;
		background: ${colors['light_grey']};
		width: 30px;
		border-radius: 50%;
		display: flex;
		height: 30px;
		align-items: center;
		justify-content: center;

		&:hover {
			background: ${colors['light_grey']};
			i {
				color: ${colors['dark_grey_2']};
			}
		}
	}
`;

const ModalRouteComponent = ({ component, ...props }: any) => {
	return (
		<ModalRouteWrapper onBackDropClick={() => props.history.goBack()}>
			<ModalRoute
				onClick={e => {
					e.stopPropagation();
				}}>
				<div
					className="close"
					onClick={() => {
						props.history.goBack();
					}}>
					<i className="las la-times"></i>
				</div>

				{component}
			</ModalRoute>
		</ModalRouteWrapper>
	);
};

export default ModalRouteComponent;
