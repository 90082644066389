import { push } from 'connected-react-router';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { $readNotification } from '../../redux/Notifications/middleware';
import { TNotification } from '../../redux/Notifications/types';
import { TGlobalState } from '../../redux/types';
import routes from '../../router/routes';
import { colors } from '../../utils/colors';
import Typography from '../Typography';

const Wrapper = styled.div`
	.item {
		cursor: pointer;
		border-top: 1px solid #444 !important;
		color: #ddd;
		padding: 10px;
		position: relative;

		&:hover {
			background-color: #222;
		}

		> div {
			display: flex;
			align-items: center;
			// img {
			// 	width: 25px;
			// 	height: 25px;
			// 	margin-right: 10px;
			// }
		}

		p {
			margin: 0;
		}

		.date {
			margin-top: 5px;
		}

		&.unread {
			font-weight: 500;
			color: white;
		}

		.unread {
			cursor: pointer;
			background-color: ${colors['figc_gold']};
			position: absolute;
			top: 5px;
			right: 5px;
			width: 7px;
			height: 7px;
			border-radius: 50%;
		}
	}

	&.dashboard {
		.item {
			color: #333;
			border-top: 0 !important;
			border-bottom: 1px solid #eee !important;

			&:hover {
				background-color: #f5f5f5;
			}
		}
	}
`;

type TProps = {
	notification: TNotification;
	className?: string;
};

const Item = ({ notification, ...props }: TProps) => {
	const dispatch = useDispatch();
	const { profile } = useSelector((state: TGlobalState) => state.Auth);

	const doAction = (): void => {
		dispatch($readNotification(notification, profile.sub));
		document.getElementById('notifications-menu')?.classList.add('hidden');

		switch (notification.type) {
			case 'SEGRETARIO_REFERTO_GARA_CREATO':
			case 'SEGRETARIO_REFERTO_GARA_INOLTRATO':
			case 'SEGRETARIO_REFERTO_GARA_SOSTITUITO':
				dispatch(
					push(
						`${routes.CHAMPIONSHIPS}/${notification.metadata.championship}${routes.MATCH_REFERTI}/${notification.metadata._id}`
					)
				);
				break;

			case 'SEGRETARIO_RICHIESTA_AMICHEVOLE_CREATA':
			case 'SEGRETARIO_RICHIESTA_AMICHEVOLE_INOLTRATA':
			case 'SEGRETARIO_RICHIESTA_AMICHEVOLE_SOSTITUITA':
				dispatch(push(`${routes.FRIENDLY_MATCH_REQUESTS}/${notification.metadata._id}`));
				break;

			case 'SEGRETARIO_RICHIESTA_VARIAZIONE_CREATA':
			case 'SEGRETARIO_RICHIESTA_VARIAZIONE_INOLTRATA':
			case 'SEGRETARIO_RICHIESTA_VARIAZIONE_SOSTITUITA':
			case 'SEGRETARIO_RICHIESTA_VARIAZIONE_APPROVATA_OTHER_CLUB':
			case 'SEGRETARIO_RICHIESTA_VARIAZIONE_RIFIUTATA_OTHER_CLUB':
				dispatch(push(`${routes.MATCH_VARIATIONS}/${notification.metadata._id}`));
				break;
		}
	};

	const handleNotificationClick = async (): Promise<void> => {
		if (!notification) {
			return;
		}

		doAction();
	};

	const isUnread = (): boolean => {
		if (!notification.recipients) {
			return false;
		}

		let recipient = notification.recipients.find((_: any) => _ && _.recipient === profile.sub);

		return recipient && !recipient.read;
	};

	return (
		<Wrapper className={props.className}>
			{notification ? (
				<div
					className={`item ${isUnread() ? 'unread' : ''}`}
					onClick={() => handleNotificationClick()}>
					<div>
						{/* <img src={notification.icon} /> */}
						<Typography.Text level={1}>{notification.contents.it}</Typography.Text>
					</div>

					<Typography.Text level={3} className={'date'}>
						{moment(notification.createdAt).format('HH:mm, DD/MM/YYYY')}
					</Typography.Text>

					{isUnread() && (
						<span
							className="unread"
							onClick={e => {
								e.stopPropagation();
								dispatch($readNotification(notification, profile.sub));
							}}></span>
					)}

					{/* <Modal
						destroyOnClose={true}
						footer={null}
						width={600}
						visible={showHTMLModal}
						onCancel={() => setShowHTMLModal(false)}
						hideConfirm
						hideSecondaryAction>
						<div className={'modal-body'}>
							<div
								className="content"
								dangerouslySetInnerHTML={{
									__html: notification.getHTMLContent(),
								}}></div>
						</div>
					</Modal> */}
				</div>
			) : (
				''
			)}
		</Wrapper>
	);
};

export default Item;
