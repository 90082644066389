export type TStateApp = {
	connectedToEywa?: boolean;
	eywaInitialized?: boolean;
};

export enum ACTIONS {
	UPDATE_STATUS = 'UPDATE_STATUS',
	RESET_STATUS = 'RESET_STATUS',
}

export interface IActUpdateStatus {
	payload: TStateApp;
	type: ACTIONS.UPDATE_STATUS;
}

export interface IActResetStatus {
	type: ACTIONS.RESET_STATUS;
}

export type TActionTypes = IActUpdateStatus | IActResetStatus;
