import _ from 'lodash';
import { useEffect, useState } from 'react';
import { DataContext } from './Context';
import { ApiQueryParams } from '../../services';
import { DataProviderProps } from '../types';
import { Subscription } from 'rxjs';
import { DataBridge } from '../DataBridge';

let subscription: Subscription | undefined;

export const HttpDataProvider = (props: DataProviderProps) => {
	const [data, setData] = useState<any[]>([]);

	const [loading, setLoading] = useState<boolean>(true);

	const [limit, setLimit] = useState<number>(10);
	const [skip, setSkip] = useState<number>(0);
	const [page, setPage] = useState<number>(1);
	const [hasMore, setHasMore] = useState<boolean>(true);

	const [filters, setFilters] = useState<any>({});
	const [search, setSearch] = useState<string | undefined>();
	const [sort, setSort] = useState<string | undefined>(undefined);

	// ------------------------------------------------
	// E v e n t s
	// ------------------------------------------------
	const onSort = (value: string | undefined) => {
		if (_.isEqual(sort, value)) return;

		loadData({
			sort: value,
		});
		setSort(value);
	};

	const onFilter = (value: any) => {
		if (_.isEqual(filters, value)) return;

		loadData({ filters: value });
		setFilters(value);
	};

	const onSearch = (value: string | undefined) => {
		if (_.isEqual(search, value)) return;

		loadData({ search: value });
		setSearch(value);
	};

	const onPrevPage = () => {
		const newSkip = skip - limit;

		loadData({ skip: newSkip });
		setSkip(newSkip);
		setPage(newSkip / limit + 1);
	};

	const onNextPage = () => {
		const newSkip = skip + limit;

		loadData({ skip: newSkip });
		setSkip(newSkip);
		setPage(newSkip / limit + 1);
	};

	// ------------------------------------------------
	// D a t a
	// ------------------------------------------------

	/**
	 * Add default values from state if params are not set.
	 *
	 * @param params
	 * @returns
	 */
	const enrichParameters = (params: ApiQueryParams): ApiQueryParams => {
		if (!params.hasOwnProperty('skip')) params.skip = skip;
		if (!params.hasOwnProperty('limit')) params.limit = limit;

		if (!params.hasOwnProperty('sort')) params.sort = sort;
		if (!params.hasOwnProperty('filters')) params.filters = filters;

		if (!params.hasOwnProperty('search')) params.search = search;

		return params;
	};

	const loadData = async (params: ApiQueryParams = {}) => {
		params = enrichParameters(params);

		setLoading(true);

		try {
			const _: any[] = await props.api.function({ ...params, ...props.api.params });

			setHasMore(!(_.length < limit));

			setData(_);
		} catch (err) {
		} finally {
			setLoading(false);
		}
	};

	// ------------------------------------------------
	// I n i t
	// ------------------------------------------------
	useEffect(() => {
		loadData();
	}, []);

	const link = () => {
		if (subscription) {
			return;
		}

		subscription = DataBridge.Instance.events().subscribe(event => {
			switch (event.type) {
				case 'update': {
					const index = data.findIndex(t => t._id === event.payload._id);
					const copy = [...data];
					copy[index] = event.payload;
					setData(copy);
				}
			}
		});
	};

	const unlink = () => {
		if (subscription) {
			subscription.unsubscribe();
			subscription = undefined;
		}
	};

	useEffect(() => {
		if (data.length) {
			unlink();
			link();
		}
	}, [data]);

	// ------------------------------------------------
	// R e n d e r
	// ------------------------------------------------
	return (
		<DataContext.Provider
			value={{
				data,
				loading,
				filters,
				onFilter,
				sort,
				onSort,
				search,
				onSearch,
				pagination: {
					page,
					canNextPage: hasMore,
					canPrevPage: skip > 0,
					onNextPage,
					onPrevPage,
				},
			}}>
			{props.children}
		</DataContext.Provider>
	);
};
