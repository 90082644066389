import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { ClubCard } from './ClubCard';

type TClubHeader = {
	logo?: string;
	club_id: number;
	club_name: string;
	team_name: string;
	is_applicant: boolean;
};

type TProps = {
	home: TClubHeader;
	away: TClubHeader;
};

const Wrapper = styled.div`
	.clubs {
		margin: 30px 0;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		/* height: 50px; */
		.home,
		.away {
			display: flex;
			flex: 1;
			align-items: center;
			gap: 20px;
			h4,
			p {
				margin: 0;
			}
			img {
				width: 50px;
			}
		}
		.home {
			justify-content: flex-start;
		}
		.away {
			justify-content: flex-end;
		}
		.versus {
			color: ${colors['dark_grey_1']};
			align-self: center;
		}
		.logo-wrapper {
			position: relative;
			.logo {
				width: 50px;
				height: 50px;
				display: flex;
				align-items: center;
			}
			img {
				width: 25px;
				height: 25px;
			}
		}
		.club-name {
			display: flex;
			flex-direction: column;
			line-height: 22px;
		}
		.invite-manager {
			color: ${colors['default_dark']};
		}
	}
`;

const Clubs = ({ home, away, ...props }: TProps) => {
	return (
		<Wrapper>
			<div className="clubs">
				<div className="home">
					<ClubCard club={home} />
				</div>
				<div className="versus">vs</div>
				<div className="away">
					<ClubCard club={away} />
				</div>
			</div>
		</Wrapper>
	);
};

export default Clubs;
