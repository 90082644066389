import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Notifications from '../../components/Notifications';
import Typography from '../../components/Typography';
import Layout from '../../layout';
import { TGlobalState } from '../../redux/types';

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto 1fr;
	gap: 20px;
	height: 100%;

	> div {
		box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 10%);
		border-radius: 10px;
		padding: 20px;
		overflow: hidden;
	}

	#support-area {
		grid-column: 1;
		grid-row: 1;
	}
	#video-area {
		grid-column: 1;
		grid-row: 2;

		.iframe-wrapper {
			margin-top: 20px;
			position: relative;
			overflow: hidden;
			width: 100%;
			padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

			> iframe {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
	#alerts-area {
		grid-column: 2;
		grid-row: span 2;

		.header {
			height: 30px;
			display: flex;
			align-items: center;
		}
	}
`;

const View = () => {
	const messages = useSelector((store: TGlobalState) => store.Locale.messages);

	const delegation = useSelector((store: TGlobalState) => store.Delegations.current);

	useEffect(() => {}, [delegation]);

	return (
		<>
			<Layout.Header>
				<Typography.Title>{messages['dashboard']}</Typography.Title>
			</Layout.Header>

			<Layout.Content>
				<Wrapper>
					<div id="support-area">
						<Typography.Title level={2}>{messages['support_area']}</Typography.Title>
						<Typography.Text>{delegation?.support_text}</Typography.Text>

						<div style={{ display: 'flex', gap: 20 }}>
							<Typography.Text>{messages['email']}:</Typography.Text>
							<Typography.Text>{delegation?.support_email}</Typography.Text>
						</div>

						<div style={{ display: 'flex', gap: 20, marginTop: -25 }}>
							<Typography.Text>{messages['phone']}:</Typography.Text>
							<Typography.Text>{delegation?.support_tel}</Typography.Text>
						</div>
					</div>

					{/* <div id="video-area">
						<Typography.Title level={2}>{messages['tutorial']}</Typography.Title>

						<div className="iframe-wrapper">
							<iframe
								src="https://www.youtube.com/embed/nWPQCNQfMUk"
								title="YouTube video player"
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen></iframe>
						</div>
					</div> */}

					<div id="alerts-area">
						<div className="header">
							<Typography.Title level={2}>
								{messages['notices_and_notifications']}
							</Typography.Title>
						</div>
						<Notifications.List
							className="dashboard"
							style={{ height: 'calc(100vh - 80px - 30px - 30px - 20px)' }}
						/>
					</div>
				</Wrapper>
			</Layout.Content>
		</>
	);
};

export default View;
