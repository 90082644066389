export type TState = {
	regions: any;
	provinces: any;
	countries: any;
};

export enum ACTIONS {
	LOAD = 'LOAD_LOCATIONS',
}

export type TLoad = {
	payload: TState;
	type: ACTIONS.LOAD;
}

export type TActions = TLoad;
