import { ERichiestaVariazioneStatus, TObjRichiestaVariazione } from '@golee/gle-types';
import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import toast from '../../../components/Toast/toast';
import { DataBridge } from '../../../providers/DataBridge';
import routes from '../../../router/routes';
import { VariationRequestsService } from '../../../services';
import View from './view';

const Container = ({ ...props }) => {
	const dispatch = useDispatch();
	const [current, setCurrent] = useState<TObjRichiestaVariazione>();

	const { id } = props.match.params;

	const dispatchUpdate = (payload: TObjRichiestaVariazione) => {
		DataBridge.Instance.dispatch({
			type: 'update',
			payload,
		});
	};

	const onLoad = async () => {
		try {
			const item: TObjRichiestaVariazione = await VariationRequestsService.getOne(id);

			setCurrent(item);
		} catch (err) {
			toast.error('Richiesta non trovata');
			dispatch(push('./'));
		}
	};

	const onApprove = async (item: TObjRichiestaVariazione) => {
		try {
			await VariationRequestsService.approve(item._id);
			toast.success('Richiesta approvata');

			dispatchUpdate({
				...item,
				status: ERichiestaVariazioneStatus.APPROVED,
			});
			dispatch(push(routes.MATCH_VARIATIONS));
		} catch (e) {
			toast.error('Impossibile approvare richiesta');
		}
	};

	const onReject = async (item: TObjRichiestaVariazione, reason: string) => {
		try {
			await VariationRequestsService.reject(item._id, reason);
			toast.success('Richiesta rifiutata');

			dispatchUpdate({
				...item,
				status: ERichiestaVariazioneStatus.REJECTED,
				rejection_motivation: reason,
			});
			dispatch(push(routes.MATCH_VARIATIONS));
		} catch (e) {
			toast.error('Impossibile rifiutare richiesta');
		}
	};

	const onForward = async (item: TObjRichiestaVariazione, delegation: string) => {
		try {
			await VariationRequestsService.forward(item._id, delegation);
			toast.success('Richiesta inoltrata');

			dispatchUpdate({
				...item,
				status: ERichiestaVariazioneStatus.FORWARDED,
			});
			dispatch(push(routes.MATCH_VARIATIONS));
		} catch (e) {
			toast.error('Impossibile inoltrare richiesta');
		}
	};

	useEffect(() => {
		onLoad();
	}, []);

	return (
		<>
			{current && (
				<View
					item={current}
					onApprove={onApprove}
					onReject={onReject}
					onForward={onForward}
				/>
			)}
		</>
	);
};

export default Container;
