import styled from 'styled-components';

const Wrapper = styled.div`
	height: calc(100vh - 80px - 30px);
	padding: 0 30px;
`;

const Content = ({
	children,
	style = {},
}: {
	children: React.ReactNode;
	style?: React.CSSProperties;
}) => {
	return <Wrapper style={style}>{children}</Wrapper>;
};

export default Content;
