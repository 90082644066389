export type TPropsTabPane = {
	tab: React.ReactNode;
	key: string;
	children: React.ReactNode;
};

const TabPane = (props: TPropsTabPane) => {
	return <div style={{ display: 'flex', flex: 1 }}>{props.tab}</div>;
};

export default TabPane;
