import { useDispatch, useSelector } from 'react-redux';
import Typography from '../../../components/Typography';
import { TGlobalState } from '../../../redux/types';
import Layout from '../../../layout';
import { GoBackIcon } from '../../../components/GoBackIcon';
import { useEffect, useState } from 'react';
import { Tabs } from '../../../components/Tabs';
import { MatchRefertiList } from './RefertiReceived';
import { MatchMissingRefertiList } from './MissingReferti ';
import { push } from 'connected-react-router';
import routes from '../../../router/routes';

type TProps = {
	subparams: any;
};

const View = ({ ...props }: TProps) => {
	const dispatch = useDispatch();
	const [activeKey, setActiveKey] = useState<any>();
	const messages = useSelector((store: TGlobalState) => store.Locale.messages);

	useEffect(() => {
		setActiveKey(window.location.hash.substr(1));
	}, []);

	const onChange = (activeKey: string) => {
		setActiveKey(activeKey);
		window.location.hash = activeKey;
	};

	return (
		<>
			<Layout.Header>
				<GoBackIcon
					onClick={() => {
						dispatch(push(`${routes.CHAMPIONSHIPS}`));
					}}
					text={
						<Typography.Title level={3}>
							{messages['select_championship']}
						</Typography.Title>
					}
				/>
			</Layout.Header>

			<Tabs
				onChange={onChange}
				activeKey={activeKey}
				tabs={[
					{
						tab: (
							<Typography.Title level={3}>
								{messages['match_reports']}
							</Typography.Title>
						),
						key: 'received_referti',
						children: <MatchRefertiList subparams={props.subparams} />,
					},
					{
						tab: (
							<Typography.Title level={3}>
								{messages['missing_reports']}
							</Typography.Title>
						),
						key: 'missing_referti',
						children: <MatchMissingRefertiList subparams={props.subparams} />,
					},
				]}
			/>
		</>
	);
};

export default View;
