import styled from 'styled-components';
import Button from '../../../Button';
import Typography from '../../../Typography';
import { PaginationProps } from '../types';

const Number = styled.div`
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: inline-block;
	line-height: 30px;
	text-align: center;
	background: #eee;
`;

const Component = ({ ...props }: PaginationProps) => {
	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					gap: 10,
					marginTop: 10,
				}}>
				<Button
					onClick={props.onPrevPage}
					color="primary"
					type="link"
					disabled={!props.canPrevPage}>
					Prev
				</Button>

				<Number>
					<Typography.Text style={{ margin: 0 }}>{props.page}</Typography.Text>
				</Number>

				<Button
					onClick={props.onNextPage}
					color="primary"
					type="link"
					disabled={!props.canNextPage}>
					Next
				</Button>
			</div>
		</>
	);
};

export default Component;
