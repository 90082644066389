import styled from 'styled-components';
import { Skeleton } from '../../../../../components/Skeleton';
import Typography from '../../../../../components/Typography';

const Wrap = styled.div`
	width: 50px;

	.badge {
		border-radius: 50%;
		width: 30px;
		height: 30px;
		display: inline-block;
		line-height: 30px;
		text-align: center;
		background: #eee;
	}
`;

type TProps = {
	count: number;
	isLoading: boolean;
	onClick?: () => void;
};

const Counter = ({ count, isLoading, onClick, ...props }: TProps) => {
	return (
		<Wrap
			onClick={e => {
				if (onClick) {
					e.stopPropagation();
					onClick && onClick();
				}
			}}>
			{isLoading ? (
				<Skeleton />
			) : (
				<div className="badge">
					<Typography.Text style={{ margin: 0 }}>{count}</Typography.Text>
				</div>
			)}
		</Wrap>
	);
};

export default Counter;
