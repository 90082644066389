import { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

type TProps = {
	onBackDropClick?: () => any;
	children: any;
	modalLevel?: number;
};

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	height: 100vh;
	width: 100%;
	z-index: 999;
	overflow-y: auto;
	overflow-x: hidden;
`;

const ModalRouteWrapper = ({ onBackDropClick, children, modalLevel, ...props }: TProps) => {
	const escFunction = useCallback(event => {
		event.stopPropagation();

		if (event.keyCode === 27) {
			onBackDropClick && onBackDropClick();
		}
	}, []);

	useEffect(() => {
		// window.addEventListener('keydown', escFunction, false);
		// return () => {
		// 	window.removeEventListener('keydown', escFunction, false);
		// };
	}, []);

	return createPortal(
		<Wrapper
			onClick={onBackDropClick}
			style={{
				backgroundColor:
					modalLevel && modalLevel > 1 ? 'transparent' : 'rgba(0, 0, 0, 0.2)',
			}}>
			{children}
		</Wrapper>,
		document.getElementById('modal-root')!
	);
};

export default ModalRouteWrapper;
